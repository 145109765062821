import axios from "axios";

export const API_URI = process.env.REACT_APP_BACKEND_URL;

export const apiAxios = axios.create({
    baseURL: API_URI,
    headers: {
        "Content-Type": "application/json",
    },
});

apiAxios.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        const status = error.response.status;
        if (status === 403) {
            //TODO mostrar mensagem de erro dizendo que o usuário não tem permissão para isso
        }
        Promise.reject(error);
    }
);

export const urls = {
    HUBSPOT_CONTACTS: "/hubspot_contacts/",
    HUBSPOT_COMPANIES: "/hubspot_companies/",
};
