import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MaskedInput from "react-text-mask";
import Lottie from "react-lottie";
import { apiAxios, urls } from "../utils/axios";
import axios from "axios";
import {
    FormControl,
    TextField,
    MenuItem,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Slide,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import send from "../assets/img/send.json";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const INITIAL_STATES = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    company: "",
    from_where: "",
    hubspot_owner_id: null,
};

export default function Form(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(INITIAL_STATES);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const { seller } = useParams();

    useEffect(() => {
        if (parseInt(seller)) {
            setFields({ ...fields, hubspot_owner_id: parseInt(seller) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchData() {
            const response = await axios
                .get(
                    "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
                )
                .then(response => response.data)
                .catch(error => console.log(error));
            setStates(response);
        }
        fetchData();
    }, []);

    const classes = useStyles();

    const handleChange = event => {
        setFields({ ...fields, [event.target.name]: event.target.value });
    };

    const handleChangeState = async event => {
        setFields({ ...fields, [event.target.name]: event.target.value });
        const response = await axios
            .get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${event.target.value.id}/municipios`
            )
            .then(response => response.data)
            .catch(error => console.log(error));
        setCities(response);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);

        let success = true;
        await apiAxios
            .post(urls.HUBSPOT_CONTACTS, {
                company: fields.company,
                email: fields.email,
                firstname: fields.firstname,
                lastname: fields.lastname,
                phone: fields.phone,
                city: fields.city,
                state: fields.state.nome,
                from_where: fields.from_where,
                hubspot_owner_id: fields.hubspot_owner_id,
            })
            .then(response => false)
            .catch(error => {
                success = false;
                console.log(error);
            });

        await apiAxios
            .post(urls.HUBSPOT_COMPANIES, {
                name: fields.company,
                city: fields.city,
                state: fields.state.nome,
            })
            .then(response => false)
            .catch(error => {
                success = false;
                console.log(error);
            });

        setLoading(false);
        setOpen(success);
        setFields(INITIAL_STATES);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                className={classes.dialog}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.dialogTitle}
                >
                    <Lottie
                        options={{
                            autoplay: open,
                            loop: false,
                            animationData: send,
                        }}
                        height={200}
                        width={200}
                    />
                    Mensagem Enviada
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className={classes.dialogDescription}
                    >
                        Em breve entraremos em contato!
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={classes.formButton}
                            onClick={handleClose}
                        >
                            OK
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <div className={classes.formContent}>
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className={classes.form}
                >
                    <FormControl fullWidth>
                        <label
                            htmlFor="firstname"
                            className={classes.formLabel}
                        >
                            Nome *
                        </label>
                        <TextField
                            id="firstname"
                            type="text"
                            name="firstname"
                            className={classes.formInput}
                            value={fields.firstname}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <label htmlFor="phone" className={classes.formLabel}>
                            Telefone *
                        </label>
                        <TextField
                            id="phone"
                            type="tel"
                            name="phone"
                            className={classes.formInput}
                            value={fields.phone}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            required
                            InputProps={{
                                inputComponent: NumberMask,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <label htmlFor="email" className={classes.formLabel}>
                            E-mail *
                        </label>
                        <TextField
                            id="email"
                            name="email"
                            type="email"
                            className={classes.formInput}
                            value={fields.email}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <label htmlFor="state" className={classes.formLabel}>
                            Estado *
                        </label>
                        <TextField
                            id="state"
                            name="state"
                            select
                            className={classes.formInput}
                            value={fields.state}
                            onChange={handleChangeState}
                            variant="outlined"
                            size="small"
                        >
                            {states.map(state => (
                                <MenuItem key={state.id} value={state}>
                                    {state.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>

                    <FormControl fullWidth>
                        <label htmlFor="city" className={classes.formLabel}>
                            Cidade *
                        </label>
                        <TextField
                            id="city"
                            name="city"
                            select
                            className={classes.formInput}
                            value={fields.city}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            disabled={cities.length === 0}
                        >
                            {cities.map(state => (
                                <MenuItem key={state.id} value={state.nome}>
                                    {state.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>

                    <FormControl fullWidth>
                        <label htmlFor="company" className={classes.formLabel}>
                            Empresa *
                        </label>
                        <TextField
                            id="company"
                            type="text"
                            name="company"
                            className={classes.formInput}
                            value={fields.company}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <label
                            htmlFor="from_where"
                            className={classes.formLabel}
                        >
                            Onde nos conheceu?
                        </label>
                        <TextField
                            id="from_where"
                            name="from_where"
                            select
                            className={classes.formInput}
                            value={fields.from_where}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                        >
                            <MenuItem value="Indicação">Indicação</MenuItem>
                            <MenuItem value="Facebook">Facebook</MenuItem>
                            <MenuItem value="Linkedin">Linkedin</MenuItem>
                            <MenuItem value="Instagram">Instagram</MenuItem>
                            <MenuItem value="Evento">Evento</MenuItem>
                            <MenuItem value="Google">Google</MenuItem>
                            <MenuItem value="Notícias">Notícias</MenuItem>
                        </TextField>
                    </FormControl>

                    <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.textRequired}
                    >
                        * Campos obrigatórios
                    </Typography>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.formButton}
                    >
                        {loading ? (
                            <CircularProgress
                                size={24}
                                className={classes.formLoading}
                            />
                        ) : (
                            "Enviar"
                        )}
                    </Button>
                </form>
            </div>
        </>
    );
}

function NumberMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={[
                "(",
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
        />
    );
}

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.down("xs")]: {
        formContent: {
            width: "100%",
            backgroundColor: "#F0F0F0",
            margin: "2rem 0",
            padding: "2rem 1.7rem",
            borderRadius: "40px",
            borderRight: "10px solid #E0E0E0",
            borderBottom: "10px solid #E0E0E0",
        },
        form: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        formLabel: {
            color: "#4C4C4C",
            fontFamily: "Nunito",
            fontSize: "18px",
        },
        formInput: {
            backgroundColor: "#FFFFFF",
            marginBottom: ".5rem",
            borderRadius: "25px",
        },
        formButton: {
            width: "50%",
            backgroundColor: "#0080ff",
            padding: ".5rem 2.5rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            marginTop: ".7rem",
            "&:hover": {
                backgroundColor: "#0473e2",
            },
        },
        formLoading: {
            color: "#fff",
        },
        textRequired: {
            width: "100%",
            color: "#888",
            fontFamily: "Nunito",
            textAlign: "left",
        },
    },
    [theme.breakpoints.up("sm")]: {
        formContent: {
            backgroundColor: "#F0F0F0",
            margin: "0 6.5rem",
            padding: "2rem",
            borderRadius: "40px",
            borderRight: "10px solid #E0E0E0",
            borderBottom: "10px solid #E0E0E0",
        },
        form: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        formLabel: {
            color: "#4C4C4C",
            fontFamily: "Nunito",
            fontSize: "15px",
        },
        formInput: {
            backgroundColor: "#FFFFFF",
            marginBottom: ".3rem",
            height: "35px",
            borderRadius: "25px",
        },
        formButton: {
            width: "50%",
            backgroundColor: "#0080ff",
            padding: ".5rem 2.5rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            marginTop: ".7rem",
            "&:hover": {
                backgroundColor: "#0473e2",
            },
        },
        formLoading: {
            color: "#fff",
        },
        textRequired: {
            width: "100%",
            color: "#888",
            fontFamily: "Nunito",
            textAlign: "left",
        },
    },
}));
