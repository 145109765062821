import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Container,
    Link,
} from "@material-ui/core";

import background from "../assets/img/BG.svg";
import logoa from "../assets/img/LOGOA.svg";

function RankingCondition() {
    const classes = useStyles();
    return (
        <div className={classes.terms}>
            <Container>
                <Link href="/">
                    <img src={logoa} height="60" alt="Speedy" />
                </Link>
                <div className={classes.termsContent}>
                    <Typography variant="h4" className={classes.title}>
                        Termos e Condições do Speedy Points
                    </Typography>
                    <Typography className={classes.description}>
                        Data de vigência: 01.11.2021
                    </Typography>
                    <Typography className={classes.description}>
                        O programa Speedy Points (o "Programa") é oferecido aos Entregadores Autônomos pela Speedy, aplicativo estruturado e disponibilizado pela SPEEDY TECNOLOGIA EM INTERMEDIAÇÃO LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob nº 42.782.302/0001-09, com sede na Av Guarabira, nº 932, CXPST 033, Bairro Manaíra, João Pessoa/PB, CEP 58038-142 (tratada como "nós" ou "Speedy" adiante).                    </Typography>
                    <Typography className={classes.description}>
                        A participação no Programa está sujeita a estes termos e condições ("Termos") e palavras iniciadas com letra maiúscula, mas não definidas expressamente nestes Termos terão o mesmo significado atribuído a elas nos “Termos de Uso” e/ou na “Política de Privacidade” os quais também se aplicam ao Programa e ficam expressamente incorporados a estes Termos.
                    </Typography>
                    <Typography className={classes.description}>
                        A SPEEDY É UMA EMPRESA DE TECNOLOGIA E NÃO UMA EMPRESA FORNECEDORA DE SERVIÇOS DE TRANSPORTE, PORTANTO TODOS OS BENEFÍCIOS E RECOMPENSAS OFERECIDOS AOS ENTREGADORES AUTÔNOMOS PELO PROGRAMA SÃO OFERECIDOS "SUJEITOS À DISPONIBILIDADE", UMA VEZ QUE ESTÃO CONDICIONADOS À DISPONIBILIDADE DE PARCEIROS INDEPENDENTES PARA FORNECER OS "SERVIÇOS", BEM COMO A OUTRAS CIRCUNSTÂNCIAS QUE POSSAM IMPACTAR A DISPONIBILIDADE DE ALGUNS BENEFÍCIOS EM CERTOS MOMENTOS E LUGARES. Os benefícios e recompensas são oferecidos em conexão com o uso dos Aplicativos da SPEEDY.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Elegibilidade
                    </Typography>
                    <Typography className={classes.description}>
                        Para participar como Membro do Programa SPEEDY POINTS, o Entregador Autônomo deve ter uma Conta em situação regular. Este só pode ter apenas uma Conta participando do Programa e não poderá compartilhar sua Conta do Programa com outros Entregadores Autônomos do aplicativo SPEEDY. Uma Conta do Programa não pode ser fundida com outra ou transferida a outra pessoa. Durante a fase inicial do Programa, a SPEEDY pode disponibilizar o Programa de forma contínua ou escalonada, de modo que nem todos os Entregadores Autônomos se tornem elegíveis para o Programa ao mesmo tempo. Ao aceitar participar deste Programa, você concorda com os Termos do Programa. Se você não concordar com os Termos do Programa, deverá desinstalar o aplicativo do seu aparelho.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Como ganhar pontos
                    </Typography>
                    <Typography className={classes.description}>
                        O entregador autônomo ganha pontos ou perde pontos dependendo de suas ações quando a chamada de um pedido é direcionada par ele. As ações que ganham pontos são:
                    </Typography>
                    <Typography className={classes.description}>
                        <ul>
                            <li>• Aceitar rotas;</li>
                            <li>• Finalizar rotas de 0 a 3km;</li>
                            <li>• Finalizar rotas de 3 a 8km;</li>
                            <li>• Finalizar rotas acima de 8km;</li>
                            <li>• Coletar pedidos de estabelecimentos diferentes.</li>
                        </ul>
                    </Typography>
                    <Typography className={classes.description}>
                        Ações que acarretam na perda de pontos:
                    </Typography>
                    <Typography className={classes.description}>
                        <ul>
                            <li>• Ignorar rotas;</li>
                            <li>• Rejeitar rotas;</li>
                            <li>• Atrasar a entrega de um pedido, no prazo do entregador;</li>
                            <li>• Aceitar uma rota e não ir buscar o pedido;</li>
                            <li>• Aceitar uma rota e demorar mais de 10 minutos para coletar;</li>
                            <li>• Ações incompatíveis com os termos de uso da Speedy.</li>
                        </ul>
                    </Typography>
                    <Typography className={classes.description}>
                        Os pontos acima descritos são pontos de classificação. Esses pontos determinam a qual nível do Programa o entregador autônomo pertencerá. Os Pontos não têm valor monetário. Os Pontos serão computados na conta do Entregador Autônomo no dia posterior a entrega finalizada.
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy se reserva o direito de revogar os Pontos ganhos sobre uma determinada entrega que foi cancelada pelo Usuário Solicitante da Plataforma SPEEDY. Os Pontos também poderão ser revogados quando a Speedy entender, a seu exclusivo critério, que os Pontos foram ganhos por meio de ou em conexão com qualquer fraude, abuso da plataforma ou violação dos Termos do Programa.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Níveis do Programa e Janelas
                    </Typography>
                    <Typography className={classes.description}>
                        Todos os Entregadores Autônomos participantes do Programa são considerados "Membros do Programa". Um Entregador Autônomo poderá se inscrever no Programa por meio do Aplicativo Speedy mediante aceite destes Termos e, ao se inscrever na plataforma o Entregador Autônomo se torna um Membro do Programa.
                    </Typography>
                    <Typography className={classes.description}>
                        O Programa oferece dois Níveis, que serão determinados com base no número de Pontos de Classificação obtidos por um Membro do Programa: Iniciante e Raiz, respectivamente, em ordem crescente ("Nível" ou "Níveis"). Apenas o nível raiz oferece Benefícios  exclusivos.
                    </Typography>
                    <Typography className={classes.description}>
                        O ranking formulado no programa leva em consideração a pontuação acumulada dos últimos 30 dias da data do dia da consulta. Cada ponto adquirido tem validade de 30 dias, e após esse período o ponto é removido. Os Pontos de Classificação acumulados por um Membro do Programa determinarão o seu Nível.
                    </Typography>
                    <Typography className={classes.description}>
                        Quando o Membro do Programa atinge a quantidade mínima de Pontos para um novo Nível, ele permanecerá nesse Nível enquanto os pontos adquiridos continuarem válidos (não expirados).
                    </Typography>
                    <Typography className={classes.description}>
                        O Membro do Programa sempre estará, simultaneamente, em uma Janela de Acúmulo e uma Janela de Benefícios. Os Pontos de Classificação irão expirar no final de cada Janela de Acúmulo.
                    </Typography>
                    <Typography className={classes.description}>
                        No momento de início do Programa serão contabilizados Pontos de Classificação com base nas entregas realizadas pelo Entregador Autônomo do Programa nos últimos 30 (trinta) dias à sua inscrição.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Benefícios do Programa
                    </Typography>
                    <Typography className={classes.description}>
                        Os Benefícios para Membros do Programa são privilégios concedidos a eles e são diferentes conforme o Nível do Programa em que ele se encontra. Os Benefícios do Programa são concedidos a exclusivo critério da Speedy e poderão mudar a cada nova Janela de Benefícios. Os Benefícios do Programa não são resgatáveis ​​por Pontos e não têm valor monetário.
                    </Typography>
                    <Typography className={classes.description}>
                        Os detalhes, termos e limitações dos Benefícios do Programa podem ser encontrados nos Termos dos Benefícios do Programa Speedy Points, que são incorporados a estes Termos. Os Benefícios do Programa, uma vez obtidos, só podem ser utilizados na Janela de Benefícios.
                    </Typography>
                    <Typography className={classes.description}>
                        Os Benefícios que os entregadores do nivel Raiz terão acesso serão anunciados nas mídias sociais oficiais da Speedy, assim como, podem ser anunciados no site oficial (www.speedy.app.br).
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Como visualizar seu saldo de Pontos
                    </Typography>
                    <Typography className={classes.description}>
                        Os Membros do Programa podem visualizar, a qualquer tempo, o seu saldo dentro da opção “Ranking" no aplicativo oficial da Speedy.
                    </Typography>
                    <Typography className={classes.description}>
                        A participação no Programa é um privilégio concedido aos Membros do Programa (entregadores autônomos) e, como tal, pode ser suspensa, revogada, alterada ou rescindida a qualquer momento por nós, por qualquer motivo. Se a sua participação no Programa for encerrada, todos os Pontos associados você e quaisquer Benefícios serão perdidos imediatamente após a rescisão. Se a sua participação no Programa for encerrada devido a atividade fraudulenta ou violação aos Termos do Programa, além de perder todos os Pontos e Benefícios do Programa associados você imediatamente, você não poderá participar ou reingressar no Programa.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Alterações e Término do Programa
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy se reserva o direito de alterar ou encerrar o Programa, ou qualquer parte dele, a qualquer momento. As alterações podem ser de diferentes formas, tais como: a) determinam como os Pontos são ganhos; b) a quantidade de Pontos de Classificação necessária para atingir um determinado Nível. Nenhum Ponto será ganho após a data efetiva do encerramento do Programa.
                    </Typography>
                    <Typography className={classes.description}>
                        As alterações a estes Termos serão válidas para todos os Membros do Programa imediatamente após sua disponibilização em nosso site. Caso Você continue participando do Programa após a implementação das alterações e/ou modificações aos Termos do Programa, isso será interpretado como uma manifestação de sua expressa concordância com tais alterações.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Interpretação
                    </Typography>
                    <Typography className={classes.description}>
                        Todas as interpretações destes Termos estarão a critério exclusivo da Speedy e as decisões do Speedy serão finais.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Disclaimer
                    </Typography>
                    <Typography className={classes.description}>
                        O PROGRAMA E/OU QUALQUER UMA DE SUAS FUNCIONALIDADES PODEM NÃO ESTAR DISPONÍVEIS, SEREM IMPRECISOS OU INTERROMPIDOS DE TEMPOS EM TEMPOS POR DIVERSOS MOTIVOS. NÃO NOS RESPONSABILIZAMOS POR QUALQUER INDISPONIBILIDADE, INTERRUPÇÕES OU ERROS DO PROGRAMA OU DE SEUS RECURSOS. A SPEEDY NÃO É RESPONSÁVEL PELO USO NÃO AUTORIZADO DE PONTOS. SE SUA CONTA FOR ENCERRADA, VOCÊ PERDERÁ TODOS OS PONTOS. A SPEEDY PODE, DE TEMPOS EM TEMPOS, SOLICITAR INFORMAÇÕES DE VOCÊ PARA CONFIRMAR SUA IDENTIDADE ANTES DE PERMITIR O ACÚMULO DE SEUS PONTOS. A SPEEDY PODE ATRASAR SEU ACESSO AOS PONTOS ATÉ QUE VOCÊ ATENDA A ESTA SOLICITAÇÃO DE INFORMAÇÃO. O PROGRAMA E TODAS AS INFORMAÇÕES ACESSÍVEIS POR MEIO DELE SÃO FORNECIDAS APENAS PARA FINS INFORMATIVOS "COMO ESTÃO" E "CONFORME DISPONÍVEL". NÓS, NOSSOS PROVEDORES DE INFORMAÇÕES E SEUS AGENTES, NÃO OFERECEMOS DECLARAÇÕES OU GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO LIMITADAS A PRECISÃO, MOEDA OU INTEGRIDADE, A OPERAÇÃO DO PROGRAMA, AS INFORMAÇÕES, MATERIAIS, CONTEÚDO, DISPONIBILIDADE E PRODUTOS. TODOS OS BENEFÍCIOS DISPONIBILIZADOS PELO PROGRAMA, SE HOUVER, SÃO FORNECIDOS PELA SPEEDY  E NÃO MODIFICAM AS RELAÇÕES CONTRATUAIS ENTRE OS MEMBROS DO PROGRAMA, OS INDIVÍDUOS E ENTIDADES QUE USAM A TECNOLOGIA DA SPEEDY  PARA OFERECER BENS OU SERVIÇOS, E A SPEEDY  OU QUAISQUER AFILIADAS DA SPEEDY , EXCETO CONFORME EXPRESSAMENTE ESTABELECIDO NESTES TERMOS. NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, NÓS RENUNCIAMOS A TODAS AS GARANTIAS, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO LIMITADAS A, GARANTIAS IMPLÍCITAS OU DE COMERCIALIZAÇÃO E ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Disposições Gerais
                    </Typography>
                    <Typography className={classes.description}>
                        Caso qualquer cláusula ou disposição destes Termos seja tida como ilegal, inválida ou inexequível, total ou parcialmente, essa disposição ou parte dela será, nessa medida, considerada como não existente para os efeitos destes Termos, mas a legalidade, validade e exequibilidade das demais disposições contidas nestes Termos não serão afetadas.
                    </Typography>
                    <Typography className={classes.description}>
                        Você não poderá ceder tampouco transferir sua Conta do Programa ou estes Termos, total ou parcialmente. Você concorda que a Speedy  ceda e transfira estes Termos total ou parcialmente, inclusive: (i) para uma subsidiária ou afiliada; (ii) um adquirente de participações acionárias, negócios ou bens da Speedy ; ou (iii) para um sucessor em razão de qualquer operação societária.
                    </Typography>
                    <Typography className={classes.description}>
                        Estes Termos serão regidos e interpretados exclusivamente de acordo com as leis do Brasil.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Solução de Controvérsias
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy disponibiliza gratuitamente aos Membros do Programa uma estrutura de suporte para o tratamento de reclamações, eventualmente, surjam na plataforma. Dessa forma, os Membros do Programa comprometem-se a utilizar as ferramentas da plataforma como primeiro meio para a solução de controvérsias decorrentes do Programa. O cumprimento das medidas descritas acima visa à efetiva solução da reclamação dos Membros do Programa, ou, na hipótese de não ser resolvida, poderá servir como indicativo de demonstrar a resistência à sua pretensão.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Fale Conosco
                    </Typography>
                    <Typography className={classes.description}>
                        Se você tiver dúvidas ou comentários sobre o Programa, entre em contato conosco em <a href="mailto:contato@speedy.app.br" className={classes.mail}>contato@speedy.app.br</a>.
                    </Typography>
                </div>
            </Container>
        </div>
    );
}

export default RankingCondition;

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        terms: {
            height: "100vh",
            width: "100%",
            overflow: "hidden",
            paddingTop: "95px",
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        termsContent: {
            marginTop: "1.5rem",
            height: "70vh",
            overflowY: "scroll",
            paddingRight: "20px",
        },
        title: {
            fontSize: "2rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        subtitle: {
            fontSize: "1rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        description: {
            fontFamily: "Nunito",
            color: "#0080ff",
            marginBottom: ".5rem",
        },
        mail: {
            color: "#0080ff",
            fontWeight: "bold",
        },
    },
}));