import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./components/Header";
import Section from "./components/Section";
import Footer from "./components/Footer";

import imageQuemSomos from "./assets/img/quemsomos.json";
import imageEntregadores from "./assets/img/entregadores.json";
import imageOndeEstamos from "./assets/img/mapa.json";

export default function App() {
    const classes = useStyles();

    return (
        <>
            <Header />

            <Section
                id="inicio"
                title="Suas entregas vão decolar!"
                titleColor="#ffffff"
                titleAlign="center"
                animationScroll={true}
            />

            <Section
                id="ondeestamos"
                title="Onde Estamos?"
                titleColor="#0080ff"
                description="Expandindo nossas operações por todo o Brasil, a Speedy está em  contínuo crescimento pelo país!"
                lottie={imageOndeEstamos}
            >
                <div className={classes.contentCity}>
                    <Typography variant="h5" className={classes.city}>
                        Natal/RN
                    </Typography>

                    <Typography variant="h5" className={classes.city}>
                        João Pessoa/PB
                    </Typography>

                    <Typography variant="h5" className={classes.city}>
                        Recife/PE
                    </Typography>
                </div>

                <div className={classes.contentCity} style={{marginTop: "2rem"}}>
                    <Typography variant="h5" component="div" className={classes.cityOff}>
                        Brasília/DF
                        <span className={classes.comingSoon}>EM BREVE</span>
                    </Typography>
                </div>
            </Section>

            <Section
                id="sobre"
                title="Quem somos?"
                titleColor="#0080ff"
                description="A Speedy está no mercado para fazer suas
                entregas decolarem! Nossa equipe de
                especialistas estão prontos para te entregar a
                melhor plataforma, logística e atendimento para
                você!"
                lottie={imageQuemSomos}
            />

            <Section
                id="entregadores"
                title="Entregadores"
                titleColor="#0080ff"
                description="Aqui você é o dono do seu tempo e tenha mais autonomia! Entregue para as mais diversas empresas com a Speedy na melhor e mais segura plataforma online!"
                lottie={imageEntregadores}
                googlePlay={true}
            />

            <Section
                id="empresas"
                title="Empresas"
                titleColor="#0080ff"
                description="Para sua empresa dispomos da melhor solução em logística unida a uma plataforma online segura e de fácil acesso. Preencha o formulário e converse com nossos especialistas."
                form={true}
            />

            <Footer />
        </>
    );
}

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.down("xs")]: {
        contentCity: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "2rem",
        },
        city: {
            color: "#0080ff",
            fontFamily: "Cocogoose",
            fontSize: "1rem",
        },
        cityOff: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#7f7f7f",
            fontFamily: "Cocogoose",
            fontSize: "1rem",
        },
        comingSoon: {
            borderRadius: "12px",
            padding: ".2rem 1rem",
            textAlign: "center",
            backgroundColor: "#7f7f7f",
            fontSize: ".8rem",
            color: "#fff",
        },
    },
    [theme.breakpoints.up("sm")]: {
        contentCity: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "flex-start",
        },
        city: {
            color: "#0080ff",
            fontFamily: "Cocogoose",
        },
        cityOff: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#7f7f7f",
            fontFamily: "Cocogoose",
        },
        comingSoon: {
            borderRadius: "12px",
            padding: ".2rem 1rem",
            textAlign: "center",
            backgroundColor: "#7f7f7f",
            fontSize: ".8rem",
            color: "#fff",
        },
    },
    [theme.breakpoints.up("md")]: {
        contentCity: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        city: {
            color: "#0080ff",
            fontFamily: "Cocogoose",
        },
        cityOff: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#7f7f7f",
            fontFamily: "Cocogoose",
        },
        comingSoon: {
            borderRadius: "12px",
            padding: ".2rem 1rem",
            textAlign: "center",
            backgroundColor: "#7f7f7f",
            fontSize: ".8rem",
            color: "#fff",
        },
    },
}));
