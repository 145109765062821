import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
    AppBar,
    Toolbar,
    Link,
    Hidden,
    Typography,
    Container,
} from "@material-ui/core";
import FaqComponent from "react-faq-component";
import background from "../assets/img/BG.svg";
import Footer from "../components/Footer";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import logob from "../assets/img/LOGOB.svg";


function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


function Faq(props) {
    const classes = useStyles();

    const data = {
        title: <Typography variant="h1" className={classes.titleFaq}>
            FAQ - Perguntas Frequentes
        </Typography>,
        rows: [
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Como funciona a aprovação do cadastro na Speedy?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        O cadastro para receber chamados pela Speedy é feito exclusivamente pelo aplicativo. É necessário preencher todos os dados solicitados e enviar as fotos necessárias que o formulário requisita. A aprovação não tem um prazo especifico e está condicionada a demanda da sua região.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Como funcionam os chamados?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        Os chamados aparecem na tela do seu celular juntamente com uma notificação sonora e vibratória para que o entregador possa aceita. Na tela aparecerá o destino indicado no mapa da sua região, a distância em quilômetros e o valor a ser pago pela entrega. É possível receber mais de uma entrega em um só chamado. Assim, o entregador terá informado em sua tela a quantidade de entregas, e o valor total da rota. É importante que o entregador gerencie seu tempo e sua rota antes de aceitar uma entrega. Além disso, deve atentar-se ao tamanho de sua bolsa ou baú, para que caibam os pedidos que chamarem em sua tela.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Qual o dia de pagamento? Preciso sacar ou entra direto na minha conta?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        O saldo a receber pelo entregador será contabilizado das entregas realizadas de terça-feira de uma semana até segunda-feira da semana seguinte. Ao final da segunda-feira, as 23h59, o saldo indicado em sua carteira será o saldo a ser pago na quarta-feira. O dia seguinte ao fechamento do saldo (terça-feira) entrará no saldo a ser pago na quarta-feira da semana seguinte.
                        Não é necessário sacar, nem pagar taxa para sacar, o valor do seu saldo fechado na segunda-feira a noite cairá integralmente em sua conta bancária informada.
                        No caso de necessitar que seu crédito seja antecipado, o entregador deverá contactar o suporte através dos canais de comunicação existentes e uma taxa será cobrada para essa antecipação.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        O que pode gerar bloqueio da conta?
                    </Typography>,
                content:
                    <React.Fragment>
                        <Typography className={classes.description}>
                            O bloqueio é realizado quando o entregador descumprir os nossos Termos e Condições de Uso que ele aceita ao se cadastrar. Há algumas situações que demonstram falta de responsabilidade e compromisso por parte do entregador, que fazem com que seu cadastro não tenha continuidade com a Speedy.
                        </Typography>
                        <Typography className={classes.description}>
                            Exemplos:
                        </Typography>
                        <Typography className={classes.description}>
                            <ul>
                                <li>- Coletar um pedido e não entregar;</li>
                                <li>- Atraso excessivo na entrega ou atraso excessivo na coleta no estabelecimento que solicitou o chamado;</li>
                                <li>- Aceitar um pedido, não coletar e não avisar ao suporte que não vai fazer a entrega;</li>
                                <li>- Coletar um pedido, não conseguir entregar e retornar ao estabelecimento sem ter entrado em contato com o suporte para que este o oriente se deveria retornar ou não;</li>
                                <li>- Aceitar um pedido com retorno, entregar, e não fazer o retorno;</li>
                                <li>- Ignorar sucessivamente os chamados estando na fila de um estabelecimento, entre outras situações;</li>
                                <li>- Tentar burlar/fraude o sistema de chamados da Speedy;</li>
                                <li>- Desrespeito ou importunação com os funcionários dos estabelecimentos solicitantes e demais entregadores.</li>
                            </ul>
                        </Typography>
                        <Typography className={classes.description}>
                            A Speedy entende que cada caso em específico pode ter ou não justificativa e será analisado se o entregador solicitar uma revisão do seu caso, que será analisado em um prazo determinado. A depender do caso, e das reiterações dos exemplos supracitados, o bloqueio pode ser temporário ou permanente.
                        </Typography>
                    </React.Fragment>
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        A Speedy paga o deslocamento no caso de cancelamento do pedido?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        A Speedy não se responsabiliza pelo deslocamento até o estabelecimento no caso de um pedido aceito e cancelado pelo próprio estabelecimento antes da coleta, uma vez que o estabelecimento tem poderes para cancelar. Portanto, a Speedy não tem como restituir deslocamentos
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Como a taxa é calculada?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        A taxa da Speedy é calculada por distância da rota partindo do estabelecimento solicitante até o endereço indicado pelo cliente. No momento, utilizamos o Google Maps como instrumento parceiro para indicar a distância e assim nosso sistema calcular a menor rota e oferecer ao entregador, que se apresentará na tela com a distância e o valor oferecido por esta rota.  A Speedy coleta  inteiramente as informações do endereço de destino preenchido pelo próprio consumidor. Quaisquer erros de endereço são atribuídos ao consumidor que forneceu o endereço e ao Google que indicou a localização que recebeu. O sistema da Speedy apenas conecta a rota dos endereços que recebe de acordo com o Google, confiando no próprio consumidor que realizou o seu pedido de delivery e preencheu seus dados na hora de fazer seu pedido.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        E se o endereço estiver errado?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        No caso de endereço errado, a primeira ação do entregador deve ser entrar em contato com o suporte no número 0800 591 8795 (ligando gratuitamente ou entrando em contato pelo seu whatsapp – exceto chamadas de voz/vídeo pelo whatsapp, não atendemos dessa forma), para esclarecimento do mesmo. O endereço é responsabilidade do cliente e a empresa solicitante, no entanto, faremos de tudo para poder auxiliar o entregador a finalizar a entrega de forma rápida e eficiente. Lembrando que, como consta nos Termos e Condições de Uso da Speedy, toda entrega é de total responsabilidade do entregador parceiro, e a prioridade deve ser realizar a entrega, a não ser por motivos alheios a sua vontade.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Cheguei ao destino, mas não havia ninguém para receber. O que fazer nessa situação?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        Assim como a resposta anterior, é estritamente necessário que o entregador entre em contato com o nosso suporte e com o estabelecimento solicitante, para que estes o orientem nessa situação.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Qual a taxa mínima da Speedy?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        As taxas da Speedy variam conforme o estabelecimento parceiro. No entanto, a Speedy assume o compromisso de não pagar menos de R$ 5,00 por rota. O valor nunca será menor que isso, que geralmente é o valor das entregas mais curtas e próximas do estabelecimento solicitante. O valor aumenta conforme aumenta a distância da rota.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        O cadastro pode ser desativado em quanto tempo?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        Caso o entregador não tenha realizado nenhuma entrega por 3 meses consecutivos, a conta do entregador poderá ser desativada.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        O estabelecimento pode decidir qual modal solicitar para fazer as entregas?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        Sim, o estabelecimento parceiro pode decidir qual modal usar em sua operação. Podendo optar apenas por motos, bicicletas, carros ou todos eles.
                    </Typography>,
            },
            {
                title:
                    <Typography variant="h5" className={classes.title}>
                        Na Speedy é necessário passar por um treinamento?
                    </Typography>,
                content:
                    <Typography className={classes.description}>
                        A necessidade de treinamento é especifica por cidade. Os treinamentos ocorrem após a aprovação dos dados de cadastro. Os que forem aprovados serão convocados para o treinamento, e os que não comparecerem terão o cadastro desativado.
                    </Typography>,
            },
        ],
    };

    const styles = {
        // bgColor: 'white',
        titleTextColor: "#606060",
        rowTitleColor: "#0080ff",
        // rowContentColor: 'grey',
        arrowColor: "#0080ff",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true
    };

    return (
        <div className={classes.faqs}>
            <ElevationScroll {...props}>
                <AppBar
                    className={
                        classes.headerScroll

                    }
                >
                    <Container className={classes.headerContainer}>
                        <Toolbar className={classes.headerToolbar}>
                            <Link href={props.company ? "/" : "#inicio"}>
                                <img src={logob} height="45" alt="Speedy" />
                            </Link>

                            {!props.company && (
                                <>
                                    <Hidden smDown>
                                        <div>
                                            <Link
                                                href="/#sobre"
                                                className={classes.headerMenu}
                                            >
                                                SOBRE
                                            </Link>
                                            <Link
                                                href="/#ondeestamos"
                                                className={classes.headerMenu}
                                            >
                                                ONDE ESTAMOS
                                            </Link>
                                            <Link
                                                href="/#entregadores"
                                                className={classes.headerMenu}
                                            >
                                                ENTREGADORES
                                            </Link>
                                            <Link
                                                href="/#empresas"
                                                className={classes.headerMenu}
                                            >
                                                EMPRESAS
                                            </Link>
                                            <Link
                                                href="/faq"
                                                className={classes.headerMenu}
                                            >
                                                PARA ENTREGADORES
                                            </Link>
                                        </div>
                                    </Hidden>

                                    <Link
                                        href="https://cliente.speedy.app.br"
                                        className={classes.headerEntrar}
                                    >
                                        ENTRAR
                                    </Link>
                                </>
                            )}
                        </Toolbar>
                    </Container>
                    <Hidden mdUp>
                        <div className={classes.headerMenuMobile}>
                            <Link href="#sobre" className={classes.headerMenu}>
                                SOBRE
                            </Link>
                            <Link
                                href="/#ondeestamos"
                                className={classes.headerMenu}
                            >
                                ONDE ESTAMOS
                            </Link>
                            <Link
                                href="/#entregadores"
                                className={classes.headerMenu}
                            >
                                ENTREGADORES
                            </Link>
                            <Link
                                href="/#empresas"
                                className={classes.headerMenu}
                            >
                                EMPRESAS
                            </Link>
                        </div>
                    </Hidden>
                </AppBar>
            </ElevationScroll>
            <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
                <div>
                    <FaqComponent
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </Container>
            <Footer />
        </div>
    );
}

export default Faq;

const useStyles = makeStyles(theme => ({
    faqs: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        paddingTop: "95px",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    titleFaq: {
        fontSize: "2rem",
        fontFamily: "Cocogoose",
        color: "drakgrey",
    },
    title: {
        fontSize: "1.2rem",
        fontFamily: "Cocogoose",
        color: "#0080ff",
    },
    subtitle: {
        fontSize: "1rem",
        fontFamily: "Cocogoose",
        color: "#0080ff",
    },
    description: {
        fontFamily: "Nunito",
        color: "#505050",
        marginBottom: ".5rem",
    },
    [theme.breakpoints.down("xs")]: {
        headerTop: {
            backgroundColor: "transparent",
            transition: "all 0.2s",
        },
        headerScroll: {
            backgroundColor: "#0080ff",
            transition: "all 0.2s",
        },
        headerMenuMobile: {
            display: "flex",
            justifyContent: "space-around",
            padding: "1rem 0",
        },
        headerContainer: {
            height: "95px",
            display: "flex",
        },
        headerToolbar: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        headerMenu: {
            color: "#fff",
            fontFamily: "Cocogoose",
            fontSize: ".7rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        headerEntrar: {
            backgroundColor: "#fff",
            color: "#0080ff",
            padding: ".6rem 2rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
    },
    [theme.breakpoints.up("sm")]: {
        headerTop: {
            backgroundColor: "transparent",
            transition: "all 0.2s",
        },
        headerScroll: {
            backgroundColor: "#0080ff",
            transition: "all 0.2s",
        },
        headerMenuMobile: {
            display: "flex",
            justifyContent: "space-around",
            padding: "1rem 0",
        },
        headerContainer: {
            height: "95px",
            display: "flex",
        },
        headerToolbar: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        headerMenu: {
            color: "#fff",
            fontFamily: "Cocogoose",
            fontSize: "1rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        headerEntrar: {
            backgroundColor: "#fff",
            color: "#0080ff",
            padding: ".6rem 2rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
    },
    [theme.breakpoints.up("md")]: {
        headerTop: {
            backgroundColor: "transparent",
            transition: "all 0.2s",
        },
        headerScroll: {
            backgroundColor: "#0080ff",
            transition: "all 0.2s",
        },
        headerContainer: {
            height: "95px",
            display: "flex",
        },
        headerToolbar: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        headerMenu: {
            color: "#fff",
            fontFamily: "Cocogoose",
            marginRight: "3rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        headerEntrar: {
            backgroundColor: "#fff",
            color: "#0080ff",
            padding: ".6rem 2rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
    },
}));
