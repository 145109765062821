import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Company from "./pages/Company";
import Faq from "./pages/Faq";
import RankingCondition from "./pages/RankingCondition";
import IndicationCode from "./pages/IndicationCode";

import "./App.css";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact={true} component={App} />
            <Route path="/company/:seller" exact={true} component={Company} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy_policy" component={PrivacyPolicy} />
            <Route path="/faq" component={Faq} />
            <Route path="/ranking_condition" component={RankingCondition} />
            <Route path="/indication_code" component={IndicationCode} />
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
