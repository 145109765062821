import React from "react";
import Header from "../components/Header";
import Section from "../components/Section";

export default function Company() {
    return (
        <>
            <Header company={true} />
            <Section
                id="empresas"
                title="Empresas"
                titleColor="#0080ff"
                description="Para sua empresa dispomos da melhor solução em logística unida a uma plataforma online segura e de fácil acesso. Preencha o formulário e converse com nossos especialistas."
                form={true}
            />
        </>
    );
}
