import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Container,
    Link,
} from "@material-ui/core";

import background from "../assets/img/BG.svg";
import logoa from "../assets/img/LOGOA.svg";

function Terms() {
    const classes = useStyles();
    return (
        <div className={classes.terms}>
            <Container>
                <Link href="/">
                    <img src={logoa} height="60" alt="Speedy" />
                </Link>
                <div className={classes.termsContent}>
                    <Typography variant="h4" className={classes.title}>
                        Termos e Condições do Programa de Indicação Speedy
                    </Typography>
                    <Typography className={classes.description}>
                        Data de vigência: 15.09.2022
                    </Typography>
                    <Typography className={classes.description}>
                        O programa Indicação Speedy (o "Programa") é oferecido aos Entregadores Autônomos pela Speedy, aplicativo estruturado e disponibilizado pela SPEEDY TECNOLOGIA EM INTERMEDIAÇÃO LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob nº 42.782.302/0001-09, com sede na Av Guarabira, nº 932, CXPST 033, Bairro Manaíra, João Pessoa/PB, CEP 58038-142 (tratada como "nós" ou "Speedy" adiante). A participação no Programa está sujeita a estes termos e condições ("Termos") e palavras iniciadas com letra maiúscula, mas não definidas expressamente nestes Termos terão o mesmo significado atribuído a elas nos “Termos de Uso” e/ou na “Política de Privacidade” os quais também se aplicam ao Programa e ficam expressamente incorporados a estes Termos.
                    </Typography>
                    <Typography className={classes.description}>
                        A SPEEDY É UMA EMPRESA DE TECNOLOGIA E NÃO UMA EMPRESA FORNECEDORA DE SERVIÇOS DE TRANSPORTE, PORTANTO TODOS OS BENEFÍCIOS E RECOMPENSAS OFERECIDOS AOS ENTREGADORES AUTÔNOMOS PELO PROGRAMA SÃO OFERECIDOS "SUJEITOS À DISPONIBILIDADE", UMA VEZ QUE ESTÃO CONDICIONADOS À DISPONIBILIDADE DE PARCEIROS INDEPENDENTES PARA FORNECER OS "SERVIÇOS", BEM COMO A OUTRAS CIRCUNST NCIAS QUE POSSAM IMPACTAR A DISPONIBILIDADE DE ALGUNS BENEFÍCIOS EM CERTOS MOMENTOS E LUGARES. Os benefícios e recompensas são oferecidos em conexão com o uso dos Aplicativos da SPEEDY.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Elegibilidade
                    </Typography>
                    <Typography className={classes.description}>
                        Para participar do Programa, o Entregador Autônomo deve ter uma Conta em situação regular.
                    </Typography>
                    <Typography className={classes.description}>
                        Este só pode ter apenas uma Conta participando do Programa e não poderá compartilhar sua Conta do Programa com outros Entregadores Autônomos do aplicativo SPEEDY.
                    </Typography>
                    <Typography className={classes.description}>
                        Uma Conta do Programa não pode ser fundida com outra ou transferida a outra pessoa.
                    </Typography>
                    <Typography className={classes.description}>
                        Durante a fase inicial do Programa, a SPEEDY pode disponibilizar o Programa de forma contínua ou escalonada, de modo que nem todos os Entregadores Autônomos se tornem elegíveis para o Programa ao mesmo tempo.
                    </Typography>
                    <Typography className={classes.description}>
                        Ao aceitar participar deste Programa, você concorda com os Termos do Programa. Se você não concordar com os Termos do Programa, deverá desinstalar o aplicativo do seu aparelho.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Como funciona o programa
                    </Typography>
                    <Typography className={classes.description}>
                        Todos os entregadores ativos da Speedy receberão um código que poderá ser compartilhado com NOVOS entregadores que não possuam cadastro na Speedy. Os entregadores que compartilham o código são denominados a frente como  INDICADORES e os que utilizam o código são denominados INDICADOS.
                    </Typography>
                    <Typography className={classes.description}>
                        O código ficará atrelado a conta do INDICADOR. Para visualizar este código o app do entregador deverá estar na versão XX ou acima.
                    </Typography>
                    <Typography className={classes.description}>
                        Quando o INDICADO fizer seu cadastro através do app, o app dará a possibilidade do INDICADO informar o código recebido. O código do INDICADOR é válido enquanto durar o Programa e enquanto o limite de utilizadores do código não tenha sido atingido.
                    </Typography>
                    <Typography className={classes.description}>
                        O INDICADOR poderá compartilhar o seu código com até 2 INDICADOS, durante a vigência do programa. Quando o INDICADO utilizar o código do INDICADOR, é contabilizada uma utilização do código, mesmo que o entregador não seja aprovado em futuras etapas do processo de aprovação do entregador.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Ganhos do programa
                    </Typography>
                    <Typography className={classes.description}>
                        O programa de indicação premia com R$ 100,00 o INDICADO e o INDICADOR. Para conseguir a premiação, as seguintes condições devem ser satisfeitas:
                    </Typography>
                    <Typography className={classes.description}>
                        <ol type="a">
                            <li><b>1 -</b> O valor será creditado na carteira do INDICADO e do INDICADOR no momento que o INDICADO finalizar as primeiras 50 corridas enviadas pelo app da Speedy;</li>
                            <li><b>2 -</b> O cadastro do INDICADO e do INDICADOR devem estar ATIVOS e SEM BLOQUEIOS no momento que a condição do item 1 for atingido;</li>
                            <li><b>3 -</b> O repasse do valor de premiação é feito na remessa semanal, juntamente com os repasses das corridas.</li>
                        </ol>
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Disclaimer
                    </Typography>
                    <Typography className={classes.description}>
                        O PROGRAMA E/OU QUALQUER UMA DE SUAS FUNCIONALIDADES PODEM NÃO ESTAR DISPONÍVEIS, SEREM IMPRECISOS OU INTERROMPIDOS DE TEMPOS EM TEMPOS POR DIVERSOS MOTIVOS. NÃO NOS RESPONSABILIZAMOS POR QUALQUER INDISPONIBILIDADE, INTERRUPÇÕES OU ERROS DO PROGRAMA OU DE SEUS RECURSOS. A SPEEDY NÃO É RESPONSÁVEL PELO USO NÃO AUTORIZADO DE PONTOS. SE SUA CONTA FOR ENCERRADA, VOCÊ PERDERÁ TODOS OS PONTOS. A SPEEDY PODE, DE TEMPOS EM TEMPOS, SOLICITAR INFORMAÇÕES DE VOCÊ PARA CONFIRMAR SUA IDENTIDADE ANTES DE PERMITIR O ACÚMULO DE SEUS PONTOS. A SPEEDY PODE ATRASAR SEU ACESSO AOS PONTOS ATÉ QUE VOCÊ ATENDA A ESTA SOLICITAÇÃO DE INFORMAÇÃO. O PROGRAMA E TODAS AS INFORMAÇÕES ACESSÍVEIS POR MEIO DELE SÃO FORNECIDAS APENAS PARA FINS INFORMATIVOS "COMO ESTÃO" E "CONFORME DISPONÍVEL". NÓS, NOSSOS PROVEDORES DE INFORMAÇÕES E SEUS AGENTES, NÃO OFERECEMOS DECLARAÇÕES OU GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO LIMITADAS A PRECISÃO, MOEDA OU INTEGRIDADE, A OPERAÇÃO DO PROGRAMA, AS INFORMAÇÕES, MATERIAIS, CONTEÚDO, DISPONIBILIDADE E PRODUTOS. TODOS OS BENEFÍCIOS DISPONIBILIZADOS PELO PROGRAMA, SE HOUVER, SÃO FORNECIDOS PELA SPEEDY  E NÃO MODIFICAM AS RELAÇÕES CONTRATUAIS ENTRE OS MEMBROS DO PROGRAMA, OS INDIVÍDUOS E ENTIDADES QUE USAM A TECNOLOGIA DA SPEEDY  PARA OFERECER BENS OU SERVIÇOS, E A SPEEDY  OU QUAISQUER AFILIADAS DA SPEEDY , EXCETO CONFORME EXPRESSAMENTE ESTABELECIDO NESTES TERMOS. NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, NÓS RENUNCIAMOS A TODAS AS GARANTIAS, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO LIMITADAS A, GARANTIAS IMPLÍCITAS OU DE COMERCIALIZAÇÃO E ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Disposições Gerais
                    </Typography>
                    <Typography className={classes.description}>
                        Caso qualquer cláusula ou disposição destes Termos seja tida como ilegal, inválida ou inexequível, total ou parcialmente, essa disposição ou parte dela será, nessa medida, considerada como não existente para os efeitos destes Termos, mas a legalidade, validade e exequibilidade das demais disposições contidas nestes Termos não serão afetadas.
                    </Typography>
                    <Typography className={classes.description}>
                        Você não poderá ceder tampouco transferir sua Conta do Programa ou estes Termos, total ou parcialmente. Você concorda que a Speedy  ceda e transfira estes Termos total ou parcialmente, inclusive: (i) para uma subsidiária ou afiliada; (ii) um adquirente de participações acionárias, negócios ou bens da Speedy ; ou (iii) para um sucessor em razão de qualquer operação societária.
                    </Typography>
                    <Typography className={classes.description}>
                        Estes Termos serão regidos e interpretados exclusivamente de acordo com as leis do Brasil.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Solução de Controvérsias
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy disponibiliza gratuitamente aos Membros do Programa uma estrutura de suporte para o tratamento de reclamações, eventualmente, surjam na plataforma. Dessa forma, os Membros do Programa comprometem-se a utilizar as ferramentas da plataforma como primeiro meio para a solução de controvérsias decorrentes do Programa. O cumprimento das medidas descritas acima visa à efetiva solução da reclamação dos Membros do Programa, ou, na hipótese de não ser resolvida, poderá servir como indicativo de demonstrar a resistência à sua pretensão.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Fale Conosco
                    </Typography>
                    <Typography className={classes.description}>
                        Se você tiver dúvidas ou comentários sobre o Programa, entre em contato conosco em <a href="mailto:contato@speedy.app.br" className={classes.mail}>contato@speedy.app.br</a>.
                    </Typography>
                </div>
            </Container>
        </div>
    );
}

export default Terms;

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        terms: {
            height: "100vh",
            width: "100%",
            overflow: "hidden",
            paddingTop: "95px",
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        termsContent: {
            marginTop: "1.5rem",
            height: "70vh",
            overflowY: "scroll",
            paddingRight: "20px",
        },
        title: {
            fontSize: "2rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        subtitle: {
            fontSize: "1rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        description: {
            fontFamily: "Nunito",
            color: "#0080ff",
            marginBottom: ".5rem",
        },
        mail: {
            color: "#0080ff",
            fontWeight: "bold",
        },
    },
}));
