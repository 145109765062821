import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Link } from "@material-ui/core";

import background from "../assets/img/BG.svg";
import logoa from "../assets/img/LOGOA.svg";

function PrivacyPolicy() {
    const classes = useStyles();
    return (
        <div className={classes.terms}>
            <Container>
                <Link href="/">
                    <img src={logoa} height="60" alt="Speedy" />
                </Link>
                <div className={classes.termsContent}>
                    <Typography variant="h4" className={classes.title}>
                        Política de Privacidade - Speedy
                    </Typography>
                    <Typography className={classes.description}>
                        A presente Política de Privacidade se destina aos
                        usuários do site, aplicativo e outras propriedades
                        eletrônicas (“Plataforma”), disponibilizadas pela Speedy
                        aos Usuários Solicitantes (seus respectivos
                        representantes legais, empregados, prepostos e/ou
                        agentes, conforme aplicável), Entregadores Autônomos e
                        Destinatários Finais.
                    </Typography>
                    <Typography className={classes.description}>
                        Para as finalidades dessa Política de Privacidade, os
                        usuários da Plataforma, em conjunto com Usuários
                        Solicitantes (seus respectivos representantes legais,
                        empregados, prepostos e/ou agentes, conforme aplicável),
                        Entregadores Autônomos e Destinatários Finais, são
                        definidos como “Clientes”.
                    </Typography>
                    <Typography className={classes.description}>
                        Através dessa Política de Privacidade, os Clientes são
                        informados:
                    </Typography>
                    <Typography className={classes.description}>
                        i. Sobre quais dados pessoais são tratados pela Speedy;
                        <br />
                        ii. Para quais finalidades e por quanto tempo os dados
                        pessoais são tratados pela Speedy;
                        <br />
                        iii. Com quem os dados pessoais podem ser
                        compartilhados;
                        <br />
                        iv. Quais são os direitos dos Clientes em relação ao
                        tratamento de seus dados pessoais; e<br />
                        v. Sobre o canal a ser utilizado pelos Clientes para o
                        exercício de seus direitos de titulares de dados
                        pessoais.
                    </Typography>
                    <Typography className={classes.description}>
                        Outras políticas, notificações e informações poderão ser
                        informadas aos Clientes em relação às atividades de
                        tratamento com seus dados pessoais.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        1. Definições de cliente
                    </Typography>
                    <Typography className={classes.description}>
                        Para as finalidades dessa Política de Privacidade, os
                        termos e expressões a seguir, quando escritos em letras
                        maiúsculas, deverão ter os seguintes significados,
                        conforme definido abaixo:
                    </Typography>
                    <Typography className={classes.description}>
                        a) Cliente da Plataforma: indivíduo que acesse e/ou
                        efetue o cadastramento de perfil próprio ou de perfil
                        comercial na Plataforma, incluindo Usuários
                        Solicitantes, Entregadores Autônomos e Destinatários
                        finais;
                    </Typography>
                    <Typography className={classes.description}>
                        b) Usuário Solicitante: indivíduo ou empresa que
                        contrata os serviços de intermediação prestados pela
                        Speedy, visando a interconexão com os Entregadores
                        Autônomos, responsáveis exclusivos pela realização das
                        entregas contratadas.
                    </Typography>
                    <Typography className={classes.description}>
                        c) Entregador Autônomo: indivíduo ou empresa que utiliza
                        os serviços de interconexão de serviços de transporte de
                        pequenas cargas, documentos, alimentos e produtos
                        (“Frete”), através das tecnologias desenvolvidas pela
                        Speedy.
                    </Typography>
                    <Typography className={classes.description}>
                        d) Destinatário Final: indivíduo ou empresa aos quais se
                        destinam as entregas efetivadas por meio dos serviços
                        intermediação entre o Usuário Solicitante e o Entregador
                        Autônomo.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        2. Coleta e armazenamento de dados
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy poderá coletar os seguintes dados pessoais de
                        seus Clientes:
                    </Typography>
                    <Typography className={classes.description}>
                        • Nome completo;
                        <br />
                        • Assinatura;
                        <br />
                        • Data de nascimento;
                        <br />
                        • Nacionalidade;
                        <br />
                        • Número de telefone fixo e telefone celular;
                        <br />
                        • Estado civil;
                        <br />
                        • Endereço completo e comprovantes de endereço;
                        <br />
                        • Endereço de e-mail;
                        <br />
                        • Dados e imagens da carteira de identidade (RG);
                        <br />
                        • Dados e imagens do cadastro de pessoas físicas
                        (CPF/ME);
                        <br />
                        • Dados e imagens da Carteira Nacional de Habilitação
                        (CNH);
                        <br />
                        • Dados do veículo do Transportador, incluindo
                        informações do proprietário;
                        <br />
                        • Cópia ou números de outros documentos de identificação
                        governamentais;
                        <br />
                        • Dados pessoais contidos em contratos sociais e
                        procurações;
                        <br />
                        • Organização ou empresa a qual pertence ou esteja
                        relacionado e informações relacionadas;
                        <br />
                        • Posição, título ou cargo;
                        <br />
                        • Informações de contrato;
                        <br />
                        • Dados de geolocalização (em segundo plano) durante a
                        utilização do Aplicativo. A coleta é encerrada quando o
                        usuário informar que está indisponível ou fechar o
                        aplicativo;
                        <br />
                        • Fotografias, coletadas para os sistemas de segurança
                        da Speedy;
                        <br />
                        • Informações sobre histórico pessoal, profissional e,
                        conforme permitido pela legislação, antecedentes
                        criminais;
                        <br />
                        • Informações bancárias e financeiras, incluindo dados
                        de cartão de crédito;
                        <br />
                        • Informações sobre crédito;
                        <br />
                        • Informações técnicas, como seu endereço de IP,
                        identificação de dispositivo, dados de localização
                        (baseados no seu IP), informações sobre cookies, quando
                        o Cliente acessa o site ou outras propriedades
                        eletrônicas da Speedy; e<br />• Comunicação, verbal ou
                        escrita, mantida entre o Cliente e a Speedy.
                    </Typography>
                    <Typography className={classes.description}>
                        Note que em certas situações, como na prospecção de
                        relacionamento com os Clientes ou em cumprimento às boas
                        práticas e à regulação anticorrupção, a Speedy também
                        pode obter seus dados pessoais de outras fontes, como em
                        sites públicos ou por meio de provedores de verificação
                        de antecedentes, em estrita conformidade com as leis de
                        proteção de dados e regulamentos aplicáveis.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        3. Finalidade e duração de tratamento
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy realiza as atividades de tratamento de dados de
                        Clientes, de maneira transparente e para finalidades
                        lícitas e específicas, conforme apontadas abaixo:
                    </Typography>
                    <Typography className={classes.description}>
                        • Para permitir sua navegação na Plataforma, incluindo
                        análises agregadas ou não de comportamento de Clientes,
                        a identificação de problemas e realização de melhorias
                        na Plataforma, a personalização de recursos e o
                        monitoramento de segurança;
                        <br />
                        • Para permitir a prestação dos serviços oferecidos pela
                        Speedy, realização de cadastro, viabilização de
                        transações, permissão e viabilidade de execução de
                        contratos;
                        <br />
                        • Para atender a solicitações e contatos, receber
                        reclamações, apurar denúncias ou responder a requisições
                        dos Clientes e outros sujeitos envolvidos na operação;
                        <br />
                        • Para estabelecer contato entre a Speedy e os Clientes,
                        inclusive para garantir o adimplemento de obrigações;
                        <br />
                        • Para permitir a prestação de assistência e serviços
                        acessórios, eventualmente relacionados aos contratos
                        estabelecidos com os Clientes;
                        <br />
                        • Para realizar a gestão de relacionamento com os
                        Clientes, inclusive através de comunicação pelos meios
                        disponíveis, marketing direto, publicidade e ações
                        promocionais;
                        <br />
                        • Para fins de marketing e publicidade dos serviços da
                        Speedy, incluindo publicidade direcionada conforme
                        perfil de comportamento, consumo e/ou localização;
                        <br />
                        • Para realizar pesquisas de satisfação ou de outra
                        natureza;
                        <br />
                        • Para verificação da identidade do Cliente;
                        <br />
                        • Para o exercício legal de direitos da Speedy,
                        inclusive em contratos e processos judiciais,
                        administrativos ou arbitrais, bem como para cumprimento
                        das obrigações legais;
                        <br />
                        • Para preparar respostas a autoridades no âmbito de
                        processos judiciais ou administrativos, em cumprimento
                        de deveres legais, regulatórios ou quaisquer outros
                        licitamente exigíveis à Speedy, ou em defesa de seus
                        direitos e interesses legítimos;
                        <br />
                        • Para a realização de atividades de prevenção contra
                        fraude e atividades ilícitas, incluindo medidas para
                        proteção da Speedy, de Clientes e/ou de terceiros; e
                        <br />• Para proteção ao crédito.
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy se compromete a manter os dados pessoais
                        conforme o necessário para realizar os propósitos
                        apontados acima e realizar o descarte dos dados pessoais
                        sempre que possível e segundo possa ser razoavelmente
                        exigido, em atendimento ao disposto na legislação
                        nacional de proteção de dados. Caso o Cliente tenha
                        dúvidas sobre as práticas de retenção e descarte de
                        dados pessoais pela Speedy, poderá contatar o canal de
                        comunicação com titulares de dados pessoais, pelo
                        endereço de e-mail:{" "}
                        <a
                            href="mailto:contato@speedy.app.br"
                            className={classes.mail}
                        >
                            contato@speedy.app.br
                        </a>
                        .
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        4. Compartilhamento de dados pessoais
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy poderá compartilhar seus dados pessoais com
                        terceiros, apenas no limite do que for estritamente
                        necessário para a realização das finalidades listadas na
                        presente Política de Privacidade e conforme uma ou mais
                        hipóteses listadas a seguir:
                    </Typography>
                    <Typography className={classes.description}>
                        • Caso o terceiro em questão seja uma das controladoras,
                        subsidiárias ou afiliadas da Speedy;
                        <br />
                        • Caso o terceiro em questão seja um prestador de
                        serviços ou parceiro comercial, realizando tratamento de
                        dados pessoais em nome e no interesse da Speedy, como
                        seu operador de dados pessoais ou como controlador,
                        mediante estrita observância da legislação aplicável;
                        <br />
                        • Caso o terceiro em questão seja um órgão ou entidade
                        pública oficial, autoridade regulatória, policial ou
                        governamental, solicitando informações legitimamente no
                        contexto de fiscalizações, auditorias, processos ou
                        procedimentos legais regulares e que constituem
                        obrigações jurídicas vinculantes à Speedy, ou quando
                        sejam razoavelmente necessárias para proteger os
                        direitos da Speedy, de Clientes e/ou de terceiros;
                        <br />• Quando a Speedy tiver obtido o livre, inequívoco
                        e expresso consentimento do Cliente para o
                        compartilhamento de dados.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        5. Segurança e local de tratamento
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy se responsabiliza pela adoção e manutenção de
                        medidas razoáveis de segurança, técnicas e
                        administrativas para prevenção de tratamento irregular
                        ou ilícito dos dados pessoais dos Clientes, inclusive
                        hipóteses não autorizadas de acesso, destruição, perda,
                        alteração ou comunicação dos dados. Todos os empregados
                        da Speedy estão sujeitos a obrigações de
                        confidencialidade. Além disso, a Speedy atualiza e
                        testa, conforme as boas práticas, seus sistemas de
                        segurança com a finalidade de proteger seus dados
                        pessoais.
                    </Typography>
                    <Typography className={classes.description}>
                        AS ATIVIDADES DE TRATAMENTO DE DADOS PESSOAIS DOS
                        CLIENTES PODERÃO SER REALIZADAS FORA DO TERRITÓRIO
                        NACIONAL. EMBORA ESTES PAÍSES POSSAM TER NÍVEIS DE
                        PROTEÇÃO DE DADOS DIFERENTES DO BRASIL, A SPEEDY ADOTARÁ
                        SALVAGUARDAS, INCLUSIVE CONTRATUAIS, PARA MANTER OS
                        DIREITOS DOS CLIENTES, A CONFIDENCIALIDADE, INTEGRIDADE
                        E SEGURANÇA DA INFORMAÇÃO.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        6. Direitos do Titular dos Dados Pessoais
                    </Typography>
                    <Typography className={classes.description}>
                        Os seguintes direitos do titular de dados pessoais
                        poderão ser exercidos a qualquer momento, em
                        conformidade com a legislação aplicável, pelos Clientes
                        mediante solicitação:
                    </Typography>
                    <Typography className={classes.description}>
                        • Confirmação da existência de tratamento dos dados
                        pessoais;
                        <br />
                        • Acesso aos dados pessoais;
                        <br />
                        • Correção de dados pessoais incompletos, inexatos ou
                        desatualizados;
                        <br />
                        • Anonimização, bloqueio ou eliminação de dados
                        desnecessários, excessivos ou tratados em
                        desconformidade com o disposto na LGPD;
                        <br />
                        • Portabilidade dos dados pessoais para outro fornecedor
                        de um serviço ou produto, sujeito à regulamentação da
                        autoridade nacional;
                        <br />
                        • Apagamento ou anonimização dos dados pessoais tratados
                        com base no seu consentimento, exceto quando a lei
                        autorizar a manutenção destes dados por outro
                        fundamento;
                        <br />
                        • Informações sobre as entidades públicas e privadas com
                        as quais a Speedy tenha realizado o uso compartilhado
                        dos seus dados pessoais;
                        <br />
                        • Informações sobre a possibilidade de não dar
                        consentimento ao tratamento dos seus dados pessoais e
                        sobre as consequências de tal ação; e<br />• Revogação
                        seu consentimento, quando o tratamento tenha sido feito
                        com base no consentimento do titular.
                    </Typography>
                    <Typography className={classes.description}>
                        A Speedy facilitará o exercício de direitos pelos
                        titulares de dados, sempre que possível e nos termos da
                        lei. A Speedy possui um canal de comunicação específico
                        para gerenciar as comunicações com titulares de dados e
                        possibilitar o exercício de direitos. Os Clientes
                        poderão fazer qualquer reclamação, solicitação ou pedido
                        de informação relacionado ao tratamento de seus dados
                        pessoais, inclusive para o exercício dos direitos de
                        titular de dados indicados na presente Política de
                        Privacidade e garantidos pela legislação nacional,
                        através do e-mail{" "}
                        <a
                            href="mailto:contato@speedy.app.br"
                            className={classes.mail}
                        >
                            contato@speedy.app.br
                        </a>
                        .
                    </Typography>
                    <Typography className={classes.description}>
                        Quando o Cliente entrar em contato com a Speedy para o
                        exercício de seus direitos de titular, a Speedy poderá
                        coletar informações para fins de confirmação da
                        identidade do titular de dados, na medida de seu
                        interesse e sua segurança.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        7. Atualizações da Política de privacidade
                    </Typography>
                    <Typography className={classes.description}>
                        A presente Política de Privacidade poderá ser atualizada
                        periodicamente. Todas as alterações deverão ser
                        consideradas como de aplicação e vigência imediata,
                        exceto em caso de comunicação em contrário enviada pela
                        Speedy aos titulares de dados. A Speedy tomará medidas
                        razoáveis para comunicar os Clientes acerca das
                        atualizações nos termos da Política de Privacidade.
                    </Typography>
                </div>
            </Container>
        </div>
    );
}

export default PrivacyPolicy;

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        terms: {
            height: "100vh",
            width: "100%",
            overflow: "hidden",
            paddingTop: "95px",
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        termsContent: {
            marginTop: "1.5rem",
            height: "70vh",
            overflowY: "scroll",
            paddingRight: "20px",
        },
        title: {
            fontSize: "2rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        subtitle: {
            fontSize: "1.2rem",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#0080ff",
        },
        description: {
            fontFamily: "Nunito",
            color: "#0080ff",
            marginBottom: ".5rem",
        },
        mail: {
            color: "#0080ff",
            fontWeight: "bold",
        },
    },
}));
