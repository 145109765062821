import React from "react";
import { Grid, Container, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../assets/img/FGT.svg";
import facebook from "../assets/img/FB.svg";
import instagram from "../assets/img/IG.svg";
import linkedin from "../assets/img/IN.svg";

export default function Section(props) {
    const classes = useStyles();
    return (
        <div className={classes.footer}>
            <Container>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.footerContent}
                >
                    <div className={classes.footerMenu}>
                        <img
                            src={logo}
                            alt="Speedy"
                            className={classes.footerLogo}
                        />

                        <ul>
                            <li className={classes.footerMenuItem}>
                                <Link
                                    href="/#inicio"
                                    className={classes.footerMenuLink}
                                >
                                    Speedy
                                </Link>
                            </li>
                            <li className={classes.footerMenuItem}>
                                <Link
                                    href="/#ondeestamos"
                                    className={classes.footerMenuLink}
                                >
                                    Onde Estamos
                                </Link>
                            </li>
                            <li className={classes.footerMenuItem}>
                                <Link
                                    href="/#sobre"
                                    className={classes.footerMenuLink}
                                >
                                    Sobre
                                </Link>
                            </li>
                            <li className={classes.footerMenuItem}>
                                <Link
                                    href="/#entregadores"
                                    className={classes.footerMenuLink}
                                >
                                    Entregadores
                                </Link>
                            </li>
                            <li className={classes.footerMenuItem}>
                                <Link
                                    href="/#empresas"
                                    className={classes.footerMenuLink}
                                >
                                    Empresas
                                </Link>
                            </li>
                            <li className={classes.footerMenuItem}>
                                <Link
                                    href="/faq"
                                    className={classes.footerMenuLink}
                                >
                                    Para Entregadores
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        {/* <Typography
                            variant="h6"
                            className={classes.footerContact}
                        >
                            <Link
                                href="tel:+5500000000000"
                                className={classes.footerLink}
                            >
                                (00) 0000-0000
                            </Link>
                        </Typography> */}
                        <Typography
                            variant="h6"
                            className={classes.footerContact}
                        >
                            <Link
                                href="mailto:contato@speedy.app.br"
                                className={classes.footerLink}
                            >
                                contato@speedy.app.br
                            </Link>
                        </Typography>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Link
                                href="https://www.instagram.com/app_speedy/"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src={instagram}
                                    alt="Instagram"
                                    width="40"
                                />
                            </Link>

                            <Link
                                href="https://www.facebook.com/appspeedybr"
                                target="_blank"
                                rel="noopener"
                            >
                                <img src={facebook} alt="Facebook" width="40" />
                            </Link>

                            <Link
                                href="https://www.linkedin.com/company/speedyapp/"
                                target="_blank"
                                rel="noopener"
                            >
                                <img src={linkedin} alt="Linkedin" width="40" />
                            </Link>
                        </Grid>
                    </div>
                </Grid>
            </Container>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.footerTerms}
            >
                &copy; Speedy, 2021 -
                <Link href="/terms" className={classes.footerTermsLink}>
                    Termos de Uso do Entregador
                </Link>
                /
                <Link
                    href="/privacy_policy"
                    className={classes.footerTermsLink}
                >
                    Política de Privacidade
                </Link>
                /
                <Link href="/ranking_condition" className={classes.footerTermsLink}>
                    Condições do Ranking
                </Link>
                
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.down("sm")]: {
        footer: {
            marginTop: "25vh",
            backgroundColor: "#0080ff",
            width: "100%",
        },
        footerContent: {
            height: "300px",
        },
        footerLogo: {
            width: "60px",
            marginBottom: "1rem",
        },
        footerMenu: {
            display: "flex",
            flexDirection: "column",
        },
        footerMenuItem: {
            listStyle: "none",
            padding: ".3rem 0",
        },
        footerMenuLink: {
            color: "#fff",
            fontFamily: "Nunito",
            fontWeight: "bold",
            fontSize: "1.1rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        footerContact: {
            textAlign: "center",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#fff",
            marginBottom: ".5rem",
        },
        footerLink: {
            color: "#fff",
            fontSize: "18px",
            "&:hover": {
                textDecoration: "none",
            },
        },
        footerTerms: {
            height: "60px",
            borderTop: "1px solid rgba(255,255,255,.1)",
            fontFamily: "Nunito",
            color: "rgba(255,255,255,0.5)",
        },
        footerTermsLink: {
            fontFamily: "Nunito",
            color: "rgba(255,255,255,0.5)",
            marginLeft: "5px",
            marginRight: "5px",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
                color: "#fff",
            },
        },
    },
    [theme.breakpoints.up("md")]: {
        footer: {
            backgroundColor: "#0080ff",
            width: "100%",
        },
        footerContent: {
            height: "250px",
        },
        footerMenu: {
            display: "flex",
            alignItems: "center",
        },
        footerMenuItem: {
            listStyle: "none",
            marginLeft: "2rem",
            padding: ".4rem 0",
        },
        footerMenuLink: {
            whiteSpace: "nowrap",
            color: "#fff",
            fontFamily: "Nunito",
            fontWeight: "bold",
            fontSize: "1.1rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        footerContact: {
            textAlign: "center",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#fff",
            marginBottom: "1rem",
        },
        footerLink: {
            color: "#fff",
            "&:hover": {
                textDecoration: "none",
            },
        },
        footerTerms: {
            height: "40px",
            borderTop: "1px solid rgba(255,255,255,.1)",
            fontFamily: "Nunito",
            color: "rgba(255,255,255,0.5)",
        },
        footerTermsLink: {
            fontFamily: "Nunito",
            color: "rgba(255,255,255,0.5)",
            marginLeft: "5px",
            marginRight: "5px",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
                color: "#fff",
            },
        },
    },
}));
