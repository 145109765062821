import React from "react";
import Lottie from "react-lottie";
import { Grid, Container, Typography, Link, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Form from "./Form";

import backgroundHome from "../assets/img/home.png";
import background from "../assets/img/BG.svg";
import cursor from "../assets/img/cursor.json";
import googlePlay from "../assets/img/GP.svg";

export default function Section(props) {
    const classes = useStyles();
    return (
        <div
            className={classes.contentBackground}
            style={
                props.id === "inicio"
                    ? { backgroundImage: `url(${backgroundHome})` }
                    : { backgroundImage: `url(${background})` }
            }
            id={props.id}
        >
            <Hidden smUp>
                <div style={{ height: "70px" }}></div>
            </Hidden>
            <Container>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    style={{ height: "calc(100vh - 95px)" }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={6}
                        className={classes.content}
                    >
                        <Typography
                            variant="h2"
                            className={classes.contentTitle}
                            style={{ color: props.titleColor }}
                            align={props.titleAlign}
                        >
                            {props.title}
                        </Typography>

                        {props.animationScroll ? (
                            <Lottie
                                options={{
                                    autoplay: true,
                                    animationData: cursor,
                                }}
                                style={{ marginTop: "2rem" }}
                                height={70}
                                width={70}
                            />
                        ) : (
                            false
                        )}

                        {props.description ? (
                            <Typography
                                variant="body1"
                                className={classes.contentDescription}
                            >
                                {props.description}
                            </Typography>
                        ) : (
                            false
                        )}

                        {props.googlePlay ? (
                            <Link
                                href="https://play.google.com/store/apps/details?id=br.app.speedy&hl=pt&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src={googlePlay}
                                    alt="Google Play"
                                    className={classes.linkGooglePlay}
                                />
                            </Link>
                        ) : (
                            false
                        )}

                        {props.children}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={7}
                        md={6}
                        className={classes.content}
                    >
                        {props.form ? (
                            <Form />
                        ) : props.lottie ? (
                            <Lottie
                                options={{
                                    autoplay: true,
                                    animationData: props.lottie,
                                }}
                            />
                        ) : (
                            false
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.down("xs")]: {
        contentBackground: {
            height: "auto",
            width: "100%",
            paddingTop: "95px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        content: {
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        contentTitle: {
            fontFamily: "Cocogoose",
            fontSize: "2.3rem",
            textAlign: "center",
        },
        contentDescription: {
            color: "#0080ff",
            fontSize: "1.1rem",
            fontFamily: "Nunito",
            padding: "2.1rem 1rem 0 1rem",
            lineHeight: "1.3",
            textAlign: "center",
        },
        contentImage: {
            width: "90%",
        },
        linkGooglePlay: {
            width: "150px",
            marginTop: "1rem",
        },
        contentDivider: {
            display: "none",
        },
    },
    [theme.breakpoints.up("sm")]: {
        contentBackground: {
            height: "100vh",
            width: "100%",
            paddingTop: "95px",
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        contentTitle: {
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        contentDescription: {
            color: "#0080ff",
            fontSize: "1.5rem",
            fontFamily: "Nunito",
            padding: "1.2rem 2.5rem 3rem 0",
            lineHeight: "1.3",
        },
        contentImage: {
            width: "100%",
        },
        linkGooglePlay: {
            width: "200px",
        },
    },
}));
