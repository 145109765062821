import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Container,
    Link,
} from "@material-ui/core";

import background from "../assets/img/BG.svg";
import logoa from "../assets/img/LOGOA.svg";

function Terms() {
    const classes = useStyles();
    return (
        <div className={classes.terms}>
            <Container>
                <Link href="/">
                    <img src={logoa} height="60" alt="Speedy" />
                </Link>
                <div className={classes.termsContent}>
                    <Typography variant="h4" className={classes.title}>
                        Termos de Uso do Entregador
                    </Typography>
                    <Typography className={classes.description}>
                        Data de vigência: 15.12.2022
                    </Typography>
                    <Typography className={classes.description}>
                        <b>Termos de Uso da Plataforma SPEEDY e da Prestação de Serviços de Cobrança e Informações Cadastrais para o Entregador Autônomo que fizer a adesão espontânea à Plataforma.</b>
                    </Typography>
                    <Typography className={classes.description}>
                        Este Termos e Condições de Uso está estruturado da seguinte forma:
                    </Typography>
                    <Typography className={classes.description}>
                        Os presentes Termos e Condições de Uso “Termos de Uso” regulamentam o acesso e a utilização da plataforma disponibilizada por meio de aplicações de internet aos interessados no compartilhamento da atividade de entrega (“Entregador Autônomo” ou “Entregadores Autônomos”), de forma independente, através da referida plataforma do SPEEDY. Desse modo, o SPEEDY e o Entregador, quando em conjunto, serão denominados “Partes” e, individualmente, “Parte”.
                    </Typography>
                    <Typography className={classes.description}>
                        Tendo em vista o nosso compromisso assumido em buscar transparência, segurança e qualidade nas operações realizadas através do SPEEDY, desenvolvemos cuidadosamente os presentes Termos de Uso para que a parte tenha conhecimento das condições gerais de uso, como de todas as regras a serem seguidas ao acessar e utilizar as funcionalidades do SPEEDY.
                    </Typography>
                    <Typography className={classes.description}>
                        Ao utilizar o SPEEDY, você automaticamente concorda com todas as regras deste termo e demais condições aqui mencionadas, responsabilizando-se integralmente por todos e quaisquer atos praticados por você durante o uso da plataforma. Na hipótese de você não concordar com qualquer das condições estabelecidas, informamos que você não deve utilizar o SPEEDY.
                    </Typography>
                    <Typography className={classes.description}>
                        O presente Termo de Uso estará disponível para leitura a qualquer momento, por meio do endereço <a href="https://www.speedy.app.br" target="blank">https://www.speedy.app.br/</a> ou através de hyperlink disponibilizado na Plataforma SPEEDY. O objetivo dessa política é garantir o constante aperfeiçoamento deste documento e do SPEEDY. Destacamos que o presente Termo poderá ser eventualmente alterados.
                    </Typography>
                    <Typography className={classes.description}>
                        Dessa forma, recomendamos que o presente Termo seja verificado periodicamente pela Parte/Usuário, de forma a garantir a ciência e o conhecimento de todas as alterações que eventualmente sejam realizadas no presente documento. Estabelecendo como base a data de atualização indicada no início da página.
                    </Typography>
                    <Typography className={classes.description}>
                        O  website  <a href="https://www.speedy.app.br" target="blank">https://www.speedy.app.br</a>  e o aplicativo SPEEDY são estruturados e disponibilizados pela SPEEDY TECNOLOGIA EM INTERMEDIACAO LTDA , pessoa jurídica de direito privado, inscrita no CNPJ sob nº 42.782.302/0001-09, com sede na AV GUARABIRA, nº 932, Bairro Manaira, João Pessoa/PB, CEP 58036-690, neste ato representada na forma de (“SPEEDY”); e ENTREGADOR AUTÔNOMO, de acordo com os dados informados no Cadastro, têm justo e contratado o presente Termo e Condições de Uso da Plataforma SPEEDY (“TERMO DE USO”), consoante as condições a seguir
                    </Typography>
                    <Typography className={classes.description}>
                        AO SE CADASTRAR E UTILIZAR CONTINUAMENTE OS SERVIÇOS, O ENTREGADOR PARCEIRO SPEEDY ESTARÁ DECLARANDO TER LIDO, ENTENDIDO E ACEITO OS TERMOS. CASO, A QUALQUER TEMPO, O PARCEIRO DE ENTREGA NÃO CONCORDE COM OS TERMOS, DEVERÁ CESSAR IMEDIATAMENTE A UTILIZAÇÃO DO APLICATIVO E DESINSTALÁ-LO DE SEU APARELHO.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        A Plataforma SPEEDY e o seu Objeto
                    </Typography>
                    <Typography className={classes.description}>
                        1. A SPEEDY é uma Plataforma hospedada sob o domínio "speedy.app.br" e de propriedade da SPEEDY disponível por meio da URL <a href="https://www.speedy.app.br" target="blank">www.speedy.app.br</a> (“Plataforma” ou “Plataforma SPEEDY”);
                    </Typography>
                    <Typography className={classes.description}>
                        1.1 As ferramentas encontradas na Plataforma estão sujeitas aos direitos de propriedade intelectual conforme nosso ordenamento jurídico, tratados e convenções internacionais dos quais o nosso país seja signatário.
                    </Typography>
                    <Typography className={classes.description}>
                        1.2 Os Entregadores Autônomos concordam expressamente que a utilização, comercialização e/ou reprodução de conteúdo ou parte de do que está disponibilizado na Plataforma deverá seguir  todas as exigências concernentes aos dispositivos contidos na Lei n° 9.610/98 (Lei do Direito Autoral), sob pena de caracterização de utilização indevida e/ou infração a direitos de propriedade intelectual de terceiros.                    </Typography>
                    <Typography className={classes.description}>
                        1.3 A Plataforma tem o objetivo de ofertar aos cadastrados (“Empresa Solicitante”) serviços de que possibilitam à Empresa Solicitante um ambiente virtual que executa a interconexão entre os prestadores autônomos (“Entregadores Autônomos”) de serviços de transporte de pequenas cargas, documentos, alimentos e produtos (“Frete”), através das tecnologias desenvolvidas pela SPEEDY. Desse modo, os serviços prestados pela SPEEDY às Empresa Solicitantes e ao Entregador Autônomo (“Serviços”) constituem-se:                    </Typography>
                    <Typography className={classes.description}>
                        <ul>
                            <li>• Na disponibilização da Plataforma e seu respectivo software, que tem o objetivo possibilitar o acesso/uso individual de todas as ferramentas e funções,</li>
                            <li>• Na intermediação e recebimento de demandas por conta e ordem de terceiros,</li>
                            <li>• Na prestação dos serviços de cobrança dos valores correspondentes aos fretes executados pelos Entregadores Autônomos em favor das Empresas Solicitantes e</li>
                            <li>• No suporte e manutenção adequada visando a utilização da Plataforma, bem como assistência remota para o melhor uso da Plataforma.</li>
                        </ul>
                    </Typography>
                    <Typography className={classes.description}>
                        1.4 Cada serviço de Frete é contratado diretamente pela Empresa Solicitante contratante deste serviço de forma independente, sem que a SPEEDY participe do contrato de transporte ou por ele se responsabilize. Desta forma, o Entregador Autônomo e a Empresa Solicitante reconhecem neste ato, conforme aplicável, que a SPEEDY não possui qualquer responsabilidade pelo serviço de Frete, pelos conteúdos transportados das partes envolvidas, incluindo a relação entre a Empresa Solicitante e o Entregador Autônomo por meio da Plataforma. A SPEEDY apenas propicia um ambiente virtual que permite a intermediação entre Empresa Solicitantes e Entregadores Autônomos, de forma que a Empresa Solicitante possa contratar diretamente o Entregador Autônomo para realização de serviços de Frete, sob única e exclusiva responsabilidade do Entregador Autônomo.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Do Cadastramento do Entregador Autônomo e as Regras de Utilização da Plataforma SPEEDY
                    </Typography>
                    <Typography className={classes.description}>
                        2. A pessoa física comprovadamente habilitada e a seu exclusivo critério pretender utilizar a Plataforma SPEEDY, poderá realizar o cadastro na Plataforma SPEEDY, preenchendo todos os dados necessários à conclusão do cadastro e posterior validação obrigatória. Além do preenchimento, haverá a necessidade de apresentação de todos os documentos solicitados. Tanto o cadastramento, como a utilização da Plataforma, após a validação, é uma escolha livre do Entregador Autônomo. A Plataforma SPEEDY está disponível para todos os Entregadores Autônomos que cumpra com as obrigações mencionadas no presente “TERMO DE USO”.
                    </Typography>
                    <Typography className={classes.description}>
                        2.1 Ao realizarem a adesão à Plataforma SPEEDY, os Entregadores Autônomos declaram e reconhecem que são independentes e autônomos, que não apresentam qualquer vínculo empregatício com a SPEEDY. Que se comprometem a apresentar as cópias dos documentos solicitados pela SPEEDY, no momento do cadastramento.
                    </Typography>
                    <Typography className={classes.description}>
                        2.2 Será responsabilidade exclusiva do Entregador Autônomo a autenticidade dos documentos e de todas as informações apresentadas. Em hipótese alguma, a SPEEDY será responsável direta ou indiretamente por qualquer ato do Entregador Autônomo, incluindo qualquer ilícito praticado pelo Entregador Autônomo com relação a eventual fraude e a ilícito de qualquer outra natureza.
                    </Typography>
                    <Typography className={classes.description}>
                        2.3 Para uma prestação de serviços adequada ao Entregador Autônomo, a SPEEDY precisará realizar a coleta, o armazenamento e a transmissão de dados a terceiros. Desse modo, todos os dados informados pelo Entregador Autônomo poderão ser disponibilizados a terceiros, incluindo Empresas Solicitantes da Plataforma que contratarão diretamente os serviços do Entregador Autônomo para um pedido de transporte de mercadora específico, aos terceiros que poderão ter alguma relação/interesse no pedido em questão realizado. Essas informações incluem, mas não se restringem a informações pessoais, localização; nome completo; foto de seu perfil; dados do veículo como modelo, marca, cor e placa, histórico de corridas realizadas, média de faturamento mensal, dentre outros, não havendo cláusula de confidencialidade sobre tais documentos e informações do Entregador Autônomo, conforme a delimitação expressa.
                    </Typography>
                    <Typography className={classes.description}>
                        2.4 O Entregador Autônomo declara e ratifica estar ciente de que eventual utilização de seus dados por terceiros, ainda que indevida ou que de alguma forma possa lhe trazer prejuízo, não acarretará qualquer tipo de responsabilização por parte da SPEEDY, devendo eventuais medidas serem tomadas diretamente contra o terceiro.
                    </Typography>
                    <Typography className={classes.description}>
                        2.5 O Entregador Autônomo concorda que a Empresa Solicitante poderá avaliar a sua experiência na Plataforma SPEEDY, o que poderá incluir, a percepção da Empresa Solicitante com relação ao funcionamento da Plataforma, a qualidade do serviço de intermediação de frete prestado pela SPEEDY ou mesmo o respectivo serviço de frete realizado.
                    </Typography>
                    <Typography className={classes.description}>
                        2.6 Caso tenha interesse no recebimento chamados de frete através da Plataforma SPEEDY, oriundas solicitações realizadas pelas Empresa Solicitantes, o Entregador Autônomo concorda que os chamados somente serão recebidos e transmitidos mediante conexão on-line à Plataforma SPEEDY. Eventuais problemas de conexão não serão responsabilidade direta ou indireta da SPEEDY.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Pré-requisitos, Documentação e Responsabilidades do Entregador Autônomo
                    </Typography>
                    <Typography className={classes.description}>
                        3. Para confirmação e finalização do cadastro do Entregador Autônomo na Plataforma SPEEDY, o Entregador Autônomo deverá possuir os seguintes requisitos abaixo, além de apresentar na Plataforma SPEEDY os seguintes documentos, como forma a atestar sua regularidade como Entregador Autônomo:
                    </Typography>
                    <Typography className={classes.description}>
                        <ol type="a">
                            <li><b>a)</b> Se Motociclista: Obrigatoriamente, deverá ser maior de 18 anos, deverá disponibilizar a CNH – Carteira Nacional de Habilitação, do tipo A ou AB, ter uma motocicleta com até 10 anos de fabricação; informar a placa do veículo; informar o CRLV - Certificado de registro e licenciamento do veículo, informar uma conta bancária de sua titularidade. Além dos documentos anteriormente citados, outros documentos que poderão ser solicitados conforme necessidade e conveniência da SPEEDY.</li>
                            <li><b>b)</b> Se estiver utilizando bicicleta: Obrigatoriamente, deverá ser maior de 18 anos, disponibilizar RG ou CNH, informar uma conta bancária de sua titularidade. Além dos documentos anteriormente citados, outros documentos que poderão ser solicitados conforme necessidade e conveniência da SPEEDY.</li>
                            <li><b>c)</b> Se Motorista: Obrigatoriamente, deverá ser maior de 18 anos, deverá disponibilizar a CNH – Carteira Nacional de Habilitação com a condição de EAR (“exerce atividade remunerada”), do tipo B ou C, ter um carro com até 10 anos de fabricação; informar o CRLV - Certificado de registro e licenciamento do veículo, informar a placa do veículo, informar uma conta bancária de sua titularidade. Além dos documentos anteriormente citados, outros documentos que poderão ser solicitados conforme necessidade e conveniência da SPEEDY.</li>
                        </ol>
                    </Typography>
                    <Typography className={classes.description}>
                        3.1 Sob pena de ser responsabilizado civil e criminalmente, o Entregador Autônomo declara que todos os documentos e informações cadastrais anexadas à Plataforma são autênticas.
                    </Typography>
                    <Typography className={classes.description}>
                        3.2 O Entregador Autônomo declara, para os devidos fins, que o veículo utilizado para a prestação do serviço autônomo é de sua propriedade ou, na hipótese de não ser, este declara ser de propriedade de terceiro que realizou a regular cessão da posse das veículo, sendo portanto, o responsável por quaisquer irregularidades e por possíveis penalidades.
                    </Typography>
                    <Typography className={classes.description}>
                        3.3 Destaca-se que o presente “TERMO DE USO” é firmado por livre conveniência de ambas as partes, em respeito ao princípio da livre contratação.
                    </Typography>
                    <Typography className={classes.description}>
                        3.4 O Entregador Autônomo expressamente autoriza que a SPEEDY realize a emissão de recibos eletrônicos por sua conta e ordem referentes aos pedidos de fretes realizados.
                    </Typography>
                    <Typography className={classes.description}>
                        3.5 Estado Entregador Autônomo com alguma documentação pendente, o recebimento de novos chamados de fretes através da Plataforma SPEEDY estará suspenso até a regularização definitiva da pendência.
                    </Typography>
                    <Typography className={classes.description}>
                        3.6 O Entregador Autônomo é plenamente responsável por todo e qualquer ato praticado quando da utilização da Plataforma SPEEDY quando estes estiverem diretamente vinculados ao seu login e senha.
                    </Typography>
                    <Typography className={classes.description}>
                        3.7 O Entregador Autônomo concorda que seus dados de acesso à Plataforma são pessoais e intransferíveis. Que se compromete a não fornecer seus dados a terceiros, sob pena de responsabilização legal, como a cobrança de eventuais perdas e danos e imediata exclusão do cadastro na Plataforma.
                    </Typography>
                    <Typography className={classes.description}>
                        3.8 O Entregador Autônomo tem pleno conhecimento de todos os riscos envolvidos na prestação dos serviços de transporte de mercadorias. O Entregador Autônomo tem conhecimento e preparo dos cuidados necessários de um profissional desta área, inclusive no que tocante aos riscos decorrentes do transporte de materiais ilícitos, perigosos, valores em moeda corrente (dinheiro) e/ou cheque, objetos muito valiosos (joias), seres vivos (animais e plantas), armas de fogo ou munições, materiais inflamáveis ou de fácil combustão, drogas, entorpecentes, explosivos e qualquer outro tipo de produto proibido pela legislação, costumes e moralidade, sendo seu o ônus referente a toda e qualquer despesa e prejuízo decorrentes da prestação do Frete, exonerando a SPEEDY de qualquer responsabilidade, já que a sua adesão à Plataforma SPEEDY é realizada de forma livre e de espontânea vontade para otimizar seus serviços, inexistindo qualquer ingerência da SPEEDY na prestação do serviço de transporte, que é contratado diretamente e de forma autônoma entre o Entregador Autônomo e a Empresa Solicitante.
                    </Typography>
                    <Typography className={classes.description}>
                        3.9 O Entregador Autônomo ao identificar ou suspeitar que o transporte que está realizando é de armas de fogo ou munições, drogas, entorpecentes ou materiais ilícitos de qualquer gênero, tem total liberdade para interromper a prestação de serviço de frete e se dirigir à Delegacia de Polícia mais próxima para relatar a ocorrência, deixando a cargo das Autoridades Policiais para tomarem as devidas providências legais.
                    </Typography>
                    <Typography className={classes.description}>
                        3.10 É responsabilidade exclusiva do Entregador Autônomo: dispor de equipamentos técnicos e operacionais necessários para realizar da prestação do serviço de transporte. Arcar com todas as despesas, custos, taxas, impostos e contribuições relativas à manutenção e operação do seu veículo, incluindo gastos com combustível, limpeza, ferramentas, equipamentos, impostos, vistorias, consertos, revisões, além de pagamento de eventuais pedágios, estacionamentos e/ou qualquer outra taxa decorrente da prestação do serviço de transporte de mercadoria. Possuir conhecimento pleno de todos os equipamentos técnicos e operacionais necessários para acessar a Plataforma SPEEDY, como aparelho de telefone celular compatíveis com a Plataforma, possuir conexão à internet móvel adequada, GPS, entre outros. Cumprir com todas as obrigações exigidas por lei relativas ao transporte de cargas, incluindo, mas não se limitando, a manter sua documentação regularizada e atualizada para a prestação dos serviços de frete. Manter atualizados seus dados cadastrais e documentações perante a SPEEDY, como pelo esgotamento da vigência dos documentos oficiais, aquisição de um novo veículo, de um nova linha telefônica móvel, pela mudança de domicílio, entre outro, e  responsabilizar-se pelo objeto a ser transportado, bem como por qualquer valor disponibilizado pela Empresa Solicitante ao Entregador Autônomo com relação ao frete, incluindo, mas não se limitando, valores a serem utilizados para pagamento de taxas em cartórios e correios.
                    </Typography>
                    <Typography className={classes.description}>
                        3.11 No caso do Entregador Autônomo, por qualquer motivo que seja, não concluir transporte do produto solicitado pela Empresa Solicitante e também não conseguir obter da Empresa Solicitante informações suficientes para concluir a entrega, o Entregador Autônomo ficará autorizado pela Empresa Solicitante, caso seja do interesse desta, a retornar ao ponto de origem (ponto de retirada) para devolver o item transportado.
                    </Typography>
                    <Typography className={classes.description}>
                        3.12 A SPEEDY está isenta de quaisquer responsabilidades referentes ao transporte e/ou ao conteúdo transportado, tendo somente a responsabilidade de intermediação e facilitação para conectar a Empresa Solicitante e o possível Entregador Autônomo.
                    </Typography>
                    <Typography className={classes.description}>
                        3.13 Todas as infrações de trânsito ou os danos decorrentes direta ou indireta da prestação do serviço de transporte, incluindo, mas sem se limitar: multas; pontuação na CNH; acidentes; processos de natureza judicial ou administrativa; danos e/ou extravio dos produtos entregues para o serviço de transporte; entre outros, deverão ser arcados única e exclusivamente pelo Entregador Autônomo, não devendo a SPEEDY ser responsabilizada por qualquer desses eventos citados.
                    </Typography>
                    <Typography className={classes.description}>
                        3.14 Salienta-se que o contrato de transporte será firmado exclusivamente entre Entregador Autônomo e Empresa Solicitante, sem qualquer responsabilidade da SPEEDY inclusive pela inexecução ou execução defeituosa da prestação do serviço de transporte.
                    </Typography>
                    <Typography className={classes.description}>
                        3.15 O Entregador Autônomo usufruirá da Plataforma SPEEDY com total liberdade e independência, de forma que poderá decidir: sobre o momento em que se conectará à Plataforma SPEEDY; por quanto tempo estará disponível para Plataforma SPEEDY; terá autonomia plena para aceitar ou negar qualquer chamado de serviço de frete, como também,  o horário, local e quantidade de acessos à Plataforma.
                    </Typography>
                    <Typography className={classes.description}>
                        3.16 O Entregador Autônomo confirma e tem pleno conhecimento que não existe qualquer participação da SPEEDY nas escolhas do Entregador Autônomo, este poderá acessar e usufruir da Plataforma SPEEDY como melhor lhe couber, desde que sejam respeitados os limites e finalidade do presente “TERMO DE USO”. No entanto,  a plataforma através de inteligência artificial e baseada em critérios objetivos, poderá direcionar a chamada para Entregadores Autônomos com melhor avaliação que estejam na mesma área geográfica de outros Entregadores Autônomos, no momento do chamado.
                    </Typography>
                    <Typography className={classes.description}>
                        3.17 O Entregador Autônomo assume a obrigação de suportar integralmente todos os danos diretos ou indiretos, incluindo, possíveis condenações, custos e despesas que possam ser imputados à SPEEDY em decorrência de atos ou omissões do Entregador Autônomo quando da realização da prestação do serviço de transporte ou da sua relação com a Empresa Solicitante. A obrigação ora prevista inclui, mas não se limita a, quaisquer processos administrativos, judiciais e extrajudiciais de qualquer natureza que sejam eventualmente instaurados ou ajuizados contra a SPEEDY, bem como cobrança qualquer natureza. Na ocorrência destes casos, o Entregador Autônomo autoriza desde já a retenção dos valores dos prejuízos comprovados.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Obrigações e responsabilidades durante o frete
                    </Typography>
                    <Typography className={classes.description}>
                        4. O Entregador Autônomo, ao aceitar os chamados ofertados na Plataforma SPEEDY, se compromete a realizar os melhores esforços no cumprimento das entregas. Realizando a atualização do status da entrega na Plataforma para acompanhamento em tempo real pela Empresa Solicitante ou destinatário final; com o pleno conhecimento quanto à responsabilidade de enviar informações quando da ocorrência de extravio, furto, roubo ou qualquer outra ocorrência com o produto transportado, face ser o responsável pelo produto durante o deslocamento para o frete. Proceder com a devolução do produto, após contactar os canais de suporte e o estabelecimento solicitante, na impossibilidade de finalizar a entrega, conforme orientação do Empresa Solicitante. Seguir estritamente as orientações contidas no pedido de entrega. Realizar todas as entregas no local descrito na solicitação do serviço, nos casos de endereço divergente entre o local designado no aplicativo e o local efetivo da entrega, o entregador deve acionar o suporte em todos os meios disponibilizados imediatamente a observância da divergência e aguardar para que seja atendido. Caso haja um deslocamento maior do que o previsto no momento da chamada, a SPEEDY reserva-se o direito de realizar ou não o ajuste da taxa.
                    </Typography>
                    <Typography className={classes.description}>
                        Finalizar o chamado a pena com a finalização da entrega do produto. Atualizar, em tempo real, a localização de forma fidedigna.
                    </Typography>
                    <Typography className={classes.description}>
                        4.1 O Entregador Autônomo deverá agir com zelo e proteção com os produtos transportados nos chamados por ele aceitos, podendo ser responsabilizados por qualquer dano causado aos produtos por ele entregues aos destinatários finais, exonerando a SPEEDY de toda e qualquer responsabilidade e ainda se comprometendo a ressarcir a Empresa Solicitante, o destinatário final do produto e/ou a SPEEDY de todos os danos e/ou prejuízos que este(s) venham a sofrer em decorrência de ação e/ou omissão do Entregador Autônomo;
                    </Typography>
                    <Typography className={classes.description}>
                        4.2 O Entregador Autônomo cumprirá todas as leis, regulamentos e normas em âmbito federal, estadual e municipal na execução das atividades de entrega;
                    </Typography>
                    <Typography className={classes.description}>
                        4.3 O Entregador Autônomo se compromete a agir com urbanidade e respeito com os demais Entregadores Autônomos, este ainda deve observar e respeitar todas as regras de funcionamento e boa convivência dos estabelecimentos que venha a visitar para a retirada e entregas de fretes, respeitando as Empresas Solicitantes, destinatários finais, empregados, terceiros e qualquer pessoa que tenha contato durante a utilização do aplicativo.
                    </Typography>
                    <Typography className={classes.description}>
                        4.4 O Entregador Autônomo deverá indenizar a SPEEDY, suas filiais, coligadas, controladoras, controladas, diretores, administradores, colaboradores, representantes e empregados por quaisquer danos, prejuízos, responsabilização, reclamações, processos, perdas, demandas ou despesas, incluindo, mas não se limitando a isso, honorários advocatícios, custas judiciais e ônus de sucumbência decorrentes da utilização indevida da Plataforma;
                    </Typography>
                    <Typography className={classes.description}>
                        4.5 O Entregador Autônomo está ciente de que a não observância das obrigações contidas no presente “Termo de Uso” poderá ensejar na rescisão da plataforma, quando fundamentada na qualidade e experiência da Empresa Solicitante.
                    </Typography>
                    <Typography className={classes.description}>
                        4.6 O Entregador Autônomo concorda e tem ciência que em caso de divergência na conclusão da entrega, o frete em questão não será considerado como concluído e, em razão disso, o Entregador Autônomo será notificado para apresentar justificativas e esclarecer a situação através do procedimento investigativo interno. Enquanto isso, não receberá novos chamados até a solução definitiva do problema.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Da Inexistência de Garantias
                    </Typography>
                    <Typography className={classes.description}>
                        5. O Entregador Autônomo declara ter plena ciência que a SPEEDY não dará qualquer garantia ao Serviço contratado conforme este “TERMO DE USO”, em particular, que a SPEEDY não garante que o uso do Serviço será ininterrupto, seguro ou isento de erros; ou o uso do Serviço proporcione qualquer performance ou atenda a qualquer expectativa. Portanto, por meio deste “TERMO DE USO”, a SPEEDY apenas oferece ao Entregador Autônomo seus Serviços, não havendo qualquer garantia entre as partes, incluindo de resultado ou de número mínimo de chamados realizados.
                    </Typography>
                    <Typography className={classes.description}>
                        5.1 Considerando que o Entregador Autônomo é um profissional que possui autonomia e independência, a SPEEDY não será, em nenhum momento, responsável por quaisquer consequências, prejuízos, lucros cessantes ou danos causados ao veículo ou ao próprio Entregador Autônomo em virtude dos serviços de transporte de produtos, sejam eles disponibilizados e aceitos ou não através do uso da Plataforma SPEEDY.
                    </Typography>
                    <Typography className={classes.description}>
                        5.2 A SPEEDY não tem qualquer responsabilidade direta ou indireta sobre os comentários ou avaliações disponibilizadas na Plataforma SPEEDY e, portanto, o Entregador Autônomo desde já isenta a SPEEDY de quaisquer reclamações, danos ou prejuízos decorrentes dos respectivos conteúdos.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Inexistência de Vínculo Trabalhista
                    </Typography>
                    <Typography className={classes.description}>
                        6. Como profissional autônomo e independente que adere à Plataforma SPEEDY por sua única e exclusiva vontade, o Entregador Autônomo atesta que a Plataforma SPEEDY e suas ferramentas não são essenciais para o desenvolvimento de suas atividades econômicas e que não há qualquer relação hierárquica, de dependência, subordinação ou trabalhista entre o Entregador Autônomo e a SPEEDY, podendo prestar livremente e sem ingerência da SPEEDY os seus serviços, inclusive podendo livremente prestar serviços de frete, para quem desejar prestar, não havendo exclusividade.
                    </Typography>
                    <Typography className={classes.description}>
                        6.1 Ambas as partes têm total ciência de que a relação entre elas não possui nenhuma das características previstas em lei para reconhecimento do vínculo empregatício, tratando-se de relação estritamente cível de prestação de Serviços pela SPEEDY em favor do Entregador Autônomo conforme a conveniência do Entregador Autônomo com relação a estes Serviços.
                    </Typography>
                    <Typography className={classes.description}>
                        6.2 As partes deste “TERMO DE USO” são independentes entre si e cada uma é inteiramente responsável pelos seus custos operacionais, despesas, taxas, contribuições e impostos relativos à manutenção de sua empresa e atividade.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Prestação de contas dos valores dos fretes recebidos por conta e ordem do Entregador autônomo.
                    </Typography>

                    <Typography className={classes.description}>
                        7. A SPEEDY prestará ao Entregador Autônomo serviço de cobrança conforme previstos no subitem “(c)” do item “1.3.”. Em razão do serviço, será retido mensalmente um percentual variável sobre o valor total dos fretes realizados, e, junto a isso o Entregador Autônomo uma nota fiscal do valor retido. Esse serviço será oferecido da seguinte forma:
                    </Typography>
                    <Typography className={classes.description}>
                        7.1 Repasse Semanal: Nesta modalidade, a SPEEDY efetuará a cobrança dos valores das entregas concluídas em nome do Entregador Autônomo, repassando-lhe tais valores semanalmente, sempre às quartas-feiras das semanas subsequentes às semanas das realizações dos fretes concluídos, entendendo-se por isso o período de meia noite de terça-feira até as 23h59 da segunda-feira.
                    </Typography>
                    <Typography className={classes.description}>
                        7.2 O Entregador Autônomo poderá solicitar a antecipação de recebíveis de suas entregas. A aceitação dessa antecipação ficará a critério da SPEEDY. Nessa modalidade de pagamento antecipado, o Entregador Autônomo ficará responsável não só pelos custos de transferência, como por taxas estabelecidas pela SPEEDY. A antecipação deverá ser solicitada até 12:00 da segunda-feira para o e-mail de <a href="mailto:suporte@speedy.app.br" className={classes.mail}>suporte@speedy.app.br</a>.
                    </Typography>
                    <Typography className={classes.description}>
                        7.3 O Entregador Autônomo autoriza a SPEEDY desde já a reter dos valores mencionados no item “7” e subitem “b” diretamente no seu extrato de chamados concluídos.
                    </Typography>
                    <Typography className={classes.description}>
                        7.4 O valor retido pela Plataforma nos termos do item “7.” caput, corresponde aos serviços prestados pela SPEEDY conforme item “1.3”, sendo composto por um valor global formado por valores menores correspondentes a cada um dos serviços de transporte prestados em favor da Empresa Solicitante.
                    </Typography>
                    <Typography className={classes.description}>
                        7.5 O Entregador Autônomo, então, declara ter total ciência de que o valor pago pela SPEEDY é variável mês a mês e calculado conforme acima descrito, podendo o valor percentual referente a prestação de Serviço pela SPEEDY ser alterado a critério único e exclusivo da mesma.
                    </Typography>
                    <Typography className={classes.description}>
                        7.6 Atesta estar ciente o Entregador Autônomo, ainda, que, no caso de ocorrerem ajustes durante a realização do serviço de entrega de produtos ou mesmo depois, tais como inclusão ou exclusão de pontos, remoção de qualquer cobrança indevida à Empresa Solicitante, cancelamento com ou sem cobrança mínima, contestação da realização do chamado por parte do Empresa Solicitante, entre outros, ou seja, caso a situação exposta no aceite tenha qualquer variação sem qualquer culpa da SPEEDY, o valor exposto quando do aceite da oferta de frete poderá sofrer alterações, nada podendo reclamar o Entregador Autônomo quanto a esta eventual alteração.
                    </Typography>
                    <Typography className={classes.description}>
                        7.7 A SPEEDY se reserva no direito de, eventualmente, por mera liberalidade e por um período previamente determinado, diminuir a sua porcentagem de comissão referente aos serviços prestados por ela ao Entregador Autônomo em razão de campanha promocional.
                    </Typography>
                    <Typography className={classes.description}>
                        7.8 O Entregador Autônomo atesta ter plena ciência de que a prestação de contas referente aos valores recebidos pela SPEEDY por conta e ordem do Entregador Autônomo, incluindo aqueles decorrentes dos Fretes, em razão dos Serviços prestados pela SPEEDY em favor do Entregador Autônomo, estão plenamente disponíveis da Plataforma SPEEDY para consulta do Entregador Autônomo conforme sua conveniência e a seu livre e exclusivo critério.
                    </Typography>
                    <Typography className={classes.description}>
                        7.9 Na modalidade onde o entregador estará agendado em um estabelecimento por determinado horário pelo valor garantido, todos os pedidos aceitos e/ou finalizados dentro da janela do horário de agendamento serão contabilizados para definir o valor total a receber pelo entregador (caso, o valor realizado de entregas não ultrapasse o valor do garantido).
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Autorização de Cessão de Direitos de Imagem
                    </Typography>
                    <Typography className={classes.description}>
                        8. O Entregador Autônomo, neste ato promove a cessão e transferência à SPEEDY dos direitos de uso de sua imagem eventualmente usada em campanhas e eventos produzidos e/ou patrocinados pela SPEEDY, atestando que entende como imagem qualquer forma de representação, inclusive fotográfica, bem como o processo audiovisual que resulta da fixação de imagens com ou sem som, que tenha a finalidade de criar, por meio de sua reprodução, a impressão de movimento, independentemente dos processos de sua captação, do suporte usado inicial ou posteriormente para fixa-lo, bem como dos meios utilizados para sua veiculação.
                    </Typography>
                    <Typography className={classes.description}>
                        8.1 Declara o Entregador Autônomo ainda que a presente cessão é feita em caráter universal, total e definitiva, por prazo indeterminado e a título gratuito, produzindo efeitos não só no Brasil, mas em qualquer lugar situado também fora das fronteiras nacionais.
                    </Typography>
                    <Typography className={classes.description}>
                        8.2 O Entregador Autônomo também autoriza a SPEEDY a utilizar a título gratuito e não oneroso a imagem cedida, no Brasil ou no exterior, sem qualquer limitação de tempo ou da modalidade de utilização, sem que caiba ao mesmo qualquer participação no eventual proveito econômico que direta ou indiretamente a SPEEDY venha a auferir, sendo que o presente ajuste produzirá efeitos inclusive em relação aos eventuais herdeiros e sucessores do Entregador Autônomo.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Política de Privacidade e Divulgação de Informações
                    </Typography>
                    <Typography className={classes.description}>
                        9. A SPEEDY se compromete a manter protegidos e em confidencialidade todos os dados cadastrais, incluindo o e-mail informado, e outras informações pessoais que não sejam necessárias à utilização dos Serviços na Plataforma SPEEDY.
                    </Typography>
                    <Typography className={classes.description}>
                        9.1 O Entregador Autônomo, desde já, autoriza a SPEEDY, a seu critério, preservar, armazenar todos os dados e informações enviados à Plataforma, bem como todos os seus dados pessoais, a exemplo de endereços de e-mail, endereços de IP (Internet Protocol), entre outras informações, exceto nas condições descritas no item “2.2.” do presente termo.
                    </Typography>
                    <Typography className={classes.description}>
                        9.2 O Entregador Autônomo também autoriza neste ato a SPEEDY a informar e/ou divulgar estes dados em caso de exigência legal, requisições de autoridades policiais ou se razoavelmente necessárias para o devido processo legal; fazer cumprir estes “TERMO DE USO”; responder a alegações de suposta violação de direitos de terceiros e de divulgação indevida de informações para contato de terceiros, e para proteger os direitos, a propriedade ou a segurança de terceiros ou da própria SPEEDY e de outros Entregadores Autônomos.
                    </Typography>
                    <Typography className={classes.description}>
                        9.3 A utilização da Plataforma SPEEDY implica o consentimento do Entregador Autônomo para coleta, armazenamento e uso das informações pessoais fornecidas e suas atualizações, dados de tráfego, endereços IP, entre outros.
                    </Typography>
                    <Typography className={classes.description}>
                        9.4 A SPEEDY poderá utilizar as informações e/ou dados fornecidos e/ou coletados pelo Entregador Autônomo, o que fica desde já autorizado, para aplicar nestes “TERMO DE USO”; prestar serviços ao Entregador Autônomo; e em outras hipóteses que se fizerem necessárias para a efetividade e manutenção e segurança da Plataforma.
                    </Typography>
                    <Typography className={classes.description}>
                        9.5 A SPEEDY se reserva o direito de reter informações pelo período que entender necessário para o bom funcionamento da plataforma, mesmo após o encerramento da conta do Entregador Autônomo.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Recebimento de material promocional
                    </Typography>
                    <Typography className={classes.description}>
                        10. O Entregador Autônomo que realizar cadastramento na Plataforma SPEEDY e tiver interesse na divulgação da Plataforma, poderá receber, a critério único e exclusivo da SPEEDY e na quantidade pela SPEEDY determinada, materiais: como adesivos, canetas, chaveiros entre outros.
                    </Typography>
                    <Typography className={classes.description}>
                        10.1 O Entregador Autônomo atesta ter ciência de que é o único responsável pelo uso e conservação dos Materiais, sendo o uso opcional.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Fraudes à plataforma
                    </Typography>
                    <Typography className={classes.description}>
                        11. A SPEEDY não tolerará, em hipótese alguma, condutas fraudulentas por parte do Entregador Autônomo, podendo impedir, suspender ou desativar, de forma temporária ou definitiva o seu acesso à Plataforma, até conclusão de investigação interna, sem necessidade de qualquer comunicação prévia, bem como agir judicial ou extrajudicialmente contra os envolvidos na fraude em questão.
                    </Typography>
                    <Typography className={classes.description}>
                        11.1 A SPEEDY analisará detalhadamente as suspeitas de fraude e poderá realizar a retenção do pagamento dos valores devidos ao Entregador Autônomo em virtude de atividades fraudulentas ou, ainda, descontar de pagamentos subsequentes os valores anteriormente pagos sobre os quais tenha sido constatada qualquer tipo de fraude, de forma a evitar, minimizar ou recuperar quaisquer danos ou risco de dano a SPEEDY, Empresas Solicitantes ou destinatários finais.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Suspensão de acesso ou descredenciamento da plataforma
                    </Typography>
                    <Typography className={classes.description}>
                        12. Qualquer uma das Partes, poderá, a qualquer momento, solicitar IMOTIVADAMENTE o encerramento da relação mediante descadastro do Entregador Autônomo da Plataforma, sem qualquer ônus e sem que seja devido o pagamento de qualquer valor adicional, sem necessidade de justificativa e/ou aviso prévio.
                    </Typography>
                    <Typography className={classes.description}>
                        12.1 Qualquer uma das Partes, poderá, a qualquer momento, solicitar MOTIVADAMENTE o encerramento da relação mediante descadastro do Entregador Autônomo, sem qualquer ônus e sem necessidade de aviso prévio, quando a outra Parte: não observar o disposto nestes Termos e/ou na legislação aplicável; dar causa a danos e/ou prejuízos, diretos ou indiretos a Parte contrária.
                    </Typography>
                    <Typography className={classes.description}>
                        12.2 A SPEEDY poderá desfazer o cadastro definitivamente ou inativar temporariamente o acesso do Entregador Autônomo à Plataforma, conforme o caso, sem notificação prévia, quando o Entregador Autônomo: fizer mau uso, uso indevido ou abusivo da Plataforma; for citado, de forma recorrente, em reclamações das Empresas Solicitantes da plataforma por conta da inobservância destes “TERMO DE USO”; causar danos e/ou prejuízos, diretos ou indiretos, a terceiros ou a própria SPEEDY, devido a atos ou omissões na utilização da Plataforma ou na realização das Entregas; atrasar excessivamente na realização das entregas; aceitar chamadas de entrega e não realizar a coleta, sem a devida justificativa; tratar de forma desrespeitosa ou grosseira os clientes do estabelecimento solicitante; em função de ordem judicial ou requisição legal por autoridade pública competente; por modificação da Plataforma, do cadastro do Entregador Autônomo ou do modal utilizado por ele que implique impossibilidade de realização das Entregas pelo Entregador Autônomo; por caso fortuito, força maior ou questões de segurança; pela suposta prática de qualquer infração de trânsito ou conduta que implique risco de segurança do trânsito; e em razão de divergência ou fraude quanto aos dados e às informações prestados quando do cadastro na plataforma.
                    </Typography>
                    <Typography className={classes.description}>
                        12.3 O Entregador Autônomo não terá direito a qualquer indenização ou compensação pelo descadastramento ou inativação temporária de seu acesso à Plataforma, sem prejuízo, todavia, do recebimento dos valores integrais devidos pelas Entregas já realizadas, com exceção dos casos de fraude e em linha com o disposto no item 11.
                    </Typography>
                    <Typography className={classes.description}>
                        12.4 A SPEEDY poderá alterar o status de disponibilidade do Entregador Autônomo, tornando-o offline por um determinado período de tempo, sem necessidade de justificativa ou aviso prévio, quando constatar automaticamente qualquer tipo atividade que indique que o Entregador Autônomo esteja usando o aplicativo de forma inadequada.
                    </Typography>
                    <Typography className={classes.description}>
                        12.5 A SPEEDY se reserva o direito de agir judicialmente e/ou extrajudicialmente em caso de danos sofridos pela SPEEDY, pelas Empresas Solicitantes, pelos Destinatários Finais ou por terceiros, instruindo processos criminais, cíveis e/ou administrativos nos casos previstos em lei e quando julgar necessário.
                    </Typography>
                    <Typography className={classes.description}>
                        12.6 As contas que não constarem nenhuma entrega durante 3 meses consecutivos poderão ser desativadas.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Seguro
                    </Typography>
                    <Typography className={classes.description}>
                        13. A SPEEDY, em parceria com a IZA SEGURADORA, possui seguro contra acidentes pessoais aos Entregadores Autônomos.
                    </Typography>
                    <Typography className={classes.description}>
                        Informações detalhadas sobre o tratamento dos dados submetidos à IZA SEGURADORA podem ser acessadas diretamente via aplicativo IZA, ou pelo site <a href="https://iza.com.vc/termos-e-politicas?aba=politicas" target="blank">https://iza.com.vc/termos-e-politicas?aba=politicas</a>
                    </Typography>
                    <Typography className={classes.description}>
                        Em respeito às cláusulas contratuais com a IZA SEGURADORA, todas as regras descritas abaixo devem ser seguidas pelos Entregadores Autônomos, para estarem devidamente segurados.
                    </Typography>
                    <Typography className={classes.description}>
                        13.1 Acesso à informação. A SPEEDY compromete-se a permitir o acesso pela IZA de seus sistemas de informação, por meio de uma interface de programação de aplicações (“API”), para que a IZA possa obter: (i) os dados dos Prestadores com a finalidade exclusiva de análise e aceitação do risco e emissão dos bilhetes de seguro a partir da primeira entrega a ser coberta pela IZA; e (ii) os dados das viagens realizadas por referidos Prestadores, em caso de abertura de sinistro, para a exclusiva validação dos dados do sinistro informados pelo Prestador em questão.
                    </Typography>
                    <Typography className={classes.description}>
                        13.2 Bilhetes de Seguro. Os bilhetes de seguro serão emitidos com o período de vigência de 1 (um) ano e terão a cobertura por períodos intermitentes, isto é, a cobertura terá um mecanismo de “liga e desliga” do seguro, que será vinculado às entregas abertas pelo Entregador Autônomo no aplicativo da SPEEDY e enviadas para a seguradora (“Período Intermitente” ou “Viagens”). Ou seja, a cobertura securitária ficará disponível ao Prestador durante o período em que ele estiver em trânsito para realização de entregas requeridas e aceitas via plataforma da Parceira, desde que sejam enviadas com sucesso pela plataforma Parceira para a IZA pela API instalada. A SPEEDY, irá armazenar o “ID”[1] da entrega enviada com sucesso para a IZA por meio da interface de programação de aplicações (“API”), que confirma o envio e recebimento sem erros.
                    </Typography>
                    <Typography className={classes.description}>
                        13.3 As Viagens somente serão consideradas para fins de aplicação da cobertura securitária nos seguintes casos:
                    </Typography>
                    <Typography className={classes.description}>
                        <ol type="a">
                            <li><b>13.3.1</b> caso sejam iniciadas na plataforma da Parceira pelo Prestador e sejam comunicadas como iniciadas para a IZA;</li>
                            <li><b>13.3.2</b> caso tenham seu início comunicado, porém não concluído, por diversos motivos, inclusive acidentes;</li>
                            <li><b>13.3.2.1</b> Viagens que, após aceitas e comunicadas pelo Prestador, permaneçam em aberto por um período de até 2 (duas) horas, sem que haja a Conclusão na plataforma da Parceira serão automaticamente encerradas e contabilizadas para fins de cobrança e cobertura.</li>
                        </ol>
                    </Typography>
                    <Typography className={classes.description}>
                        13.4 O Seguro da IZA não oferecerá cobertura para:
                    </Typography>
                    <Typography className={classes.description}>
                        <ol type="a">
                            <li><b>a)</b> Trajetos em que os Entregadores Autônomos estejam prestando serviços para empresas congêneres à Parceira ou quando estiverem fora de atividades relacionadas às Viagens ou em atividades pessoais.</li>
                            <li><b>b)</b> Viagens não recebidas pela IZA, não possuem cobertura.</li>
                            <li><b>c)</b> Casos omissos, como chamados de entregas que não constam na base da IZA, mas foram enviados com sucesso pelo parceiro, serão solucionados entre as partes.</li>
                        </ol>
                    </Typography>
                    <Typography className={classes.description}>
                        13.5 Demais obrigações dos Entregadores Autônomos.
                    </Typography>
                    <Typography className={classes.description}>
                        13.5.1 O Entregador Autônomo também deve observar as Condições Gerais do seguro, que podem ser acessadas a qualquer momento por meio do website e do aplicativo da IZA, bem como todas as condições do bilhete.
                    </Typography>
                    <Typography className={classes.description}>
                        <ol type="a">
                            <li><b>a)</b> O Entregador Autônomo poderá utilizar o aplicativo da IZA e esclarecer a sua importância para fins de (i) abertura de sinistros; (ii) recebimento de indenização; e (iii) esclarecimento de dúvidas;</li>
                            <li><b>b)</b> A SPEEDY é apenas Parceria e contratante do seguro, o Entregador Autônomo deve entrar em contato direto com a IZA por meio dos seus canais de contato: (11) 4673-2002 (central de atendimento telefônico), (11) 4673-2004 (whatsapp) ou chat online acessível via site <a href="https://iza.com.vc/" target="blank">www.iza.com.vc</a> ou aplicativo IZA Seguros;</li>
                            <li><b>c)</b> O Entregador Autônomo, deve manter seu cadastro na plataforma da SPEEDY sempre com os dados pessoais corretos e atualizados;</li>
                            <li><b>d)</b> A SPEEDY e a IZA possuem integração via API. Caso necessário e exigido pela IZA, o Entregador Autônomo deve enviar documentos e informações adicionais à IZA para efetiva cobertura securitária.</li>
                        </ol>
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Sinistralidade e Reajuste
                    </Typography>
                    <Typography className={classes.description}>
                        14. Sinistro. Em caso de sinistro, a impossibilidade de confirmação pela IZA dos dados do Prestador e/ou do sinistro, por qualquer razão, inclusive ausência da confirmação do recebimento do início da entrega, ou seja, ausência do ID, poderá implicar na ausência de cobertura securitária para o Entregador Autônomo.
                    </Typography>
                    <Typography className={classes.description}>
                        14.1 Além dos documentos exigidos nas Condições Gerais para a regulação do sinistro, observado o disposto na Cláusula 2.1. O Entregador Autônomo deverá encaminhar as seguintes informações para a IZA, por meio do aplicativo da IZA ou qualquer outro meio idôneo requerido pela IZA: (i) endereço do início da Viagem pelo Prestador; (ii) endereço final da Viagem e/ou endereço do sinistro; (iii) data e horário do início da Viagem e (iv) informações sobre o acidente.
                    </Typography>
                    <Typography className={classes.description}>
                        14.2 Caso seja constatada fraude ou má-fé do Prestador na utilização do Seguro e/ou na abertura e processo de sinistro, a IZA terá a prerrogativa de aprovar ou rejeitar a renovação do Seguro do referido Prestador, mantendo sempre a Parceira informada sobre tais decisões.
                    </Typography>
                    <Typography variant="h6" className={classes.subtitle}>
                        Considerações Finais
                    </Typography>
                    <Typography className={classes.description}>
                        15. Independentemente de qualquer notificação ao Entregador Autônomo, a SPEEDY poderá modificar o teor do “TERMO DE USO” e/ou da própria Plataforma SPEEDY ou de qualquer parte dela, a qualquer momento e a seu exclusivo critério, sem que caiba qualquer tipo de indenização ou ônus ao Entregador Autônomo.
                    </Typography>
                    <Typography className={classes.description}>
                        15.1 As modificações ao “TERMO DE USO” entrarão em vigor na data da publicação em seu site oficial e, ao continuar utilizando os Serviços prestados por meio da Plataforma SPEEDY, o Entregador Autônomo automaticamente concorda e se sujeita aos novos termos do “TERMO DE USO”.
                    </Typography>
                    <Typography className={classes.description}>
                        15.2 As Partes elegem o Foro Cível da Comarca de João Pessoa/PB para dirimir quaisquer controvérsias oriundas, direta ou indiretamente, deste “TERMO DE USO”, excluindo-se qualquer outro por mais privilegiado que seja.
                    </Typography>
                    <Typography className={classes.description}>
                        15.3 O Entregador Autônomo não poderá transferir, ceder ou onerar os direitos e obrigações decorrentes deste “TERMO DE USO” sem a anuência escrita da SPEEDY.
                    </Typography>
                    <Typography className={classes.description}>
                        15.4 O não exercício do direito de qualquer das partes não poderá ser interpretado como renúncia ao mesmo, não podendo os precedentes serem invocados como novação tácita.
                    </Typography>
                    <Typography className={classes.description}>
                        15.5 Qualquer divulgação dos dados confidenciais da SPEEDY que sejam repassados ao Entregador Autônomo, somente poderá ser feita mediante autorização por escrito da SPEEDY, sob pena de responsabilização cível e criminal.
                    </Typography>
                    <Typography className={classes.description}>
                        15.6 O presente “TERMO DE USO” firmado entre as partes revoga qualquer acordo anterior, verbal ou escrito, tendo como o mesmo objeto, que porventura tenha existido anteriormente entre as partes.
                    </Typography>
                    <Typography className={classes.description}>
                        15.7 Resta ciente o Entregador Autônomo que a SPEEDY não contrata serviços de transporte diretamente e nem se responsabiliza por ele.
                    </Typography>

                    <Typography variant="h6" className={classes.subtitle}>
                        Fale Conosco
                    </Typography>
                    <Typography className={classes.description}>
                        Se você tiver dúvidas ou comentários sobre o Programa,
                        entre em contato conosco em <a href="mailto:contato@speedy.app.br" className={classes.mail}>contato@speedy.app.br</a>.
                    </Typography>
                </div>
            </Container>
        </div>
    );
}

export default Terms;

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        terms: {
            height: "100vh",
            width: "100%",
            overflow: "hidden",
            paddingTop: "95px",
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        termsContent: {
            marginTop: "1.5rem",
            height: "70vh",
            overflowY: "scroll",
            paddingRight: "20px",
        },
        title: {
            fontSize: "2rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        subtitle: {
            fontSize: "1rem",
            fontFamily: "Cocogoose",
            color: "#0080ff",
        },
        description: {
            fontFamily: "Nunito",
            color: "#0080ff",
            marginBottom: ".5rem",
        },
        mail: {
            color: "#0080ff",
            fontWeight: "bold",
        },
    },
}));
