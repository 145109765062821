import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Container, Link, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import logob from "../assets/img/LOGOB.svg";

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ElevateAppBar(props) {
    const classes = useStyles();

    const scroll = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return (
        <>
            <ElevationScroll {...props}>
                <AppBar
                    className={
                        scroll || props.company
                            ? classes.headerScroll
                            : classes.headerTop
                    }
                >
                    <Container className={classes.headerContainer}>
                        <Toolbar className={classes.headerToolbar}>
                            <Link href={props.company ? "/" : "#inicio"}>
                                <img src={logob} height="45" alt="Speedy" />
                            </Link>

                            {!props.company && (
                                <>
                                    <Hidden smDown>
                                        <div>
                                            <Link
                                                href="#sobre"
                                                className={classes.headerMenu}
                                            >
                                                SOBRE
                                            </Link>
                                            <Link
                                                href="#ondeestamos"
                                                className={classes.headerMenu}
                                            >
                                                ONDE ESTAMOS
                                            </Link>
                                            <Link
                                                href="#entregadores"
                                                className={classes.headerMenu}
                                            >
                                                ENTREGADORES
                                            </Link>
                                            <Link
                                                href="#empresas"
                                                className={classes.headerMenu}
                                            >
                                                EMPRESAS
                                            </Link>
                                            <Link
                                                href="/faq"
                                                className={classes.headerMenu}
                                            >
                                                PARA ENTREGADORES
                                            </Link>
                                        </div>
                                    </Hidden>

                                    <Link
                                        href="https://cliente.speedy.app.br"
                                        className={classes.headerEntrar}
                                    >
                                        ENTRAR
                                    </Link>
                                </>
                            )}
                        </Toolbar>
                    </Container>
                    <Hidden mdUp>
                        <div className={classes.headerMenuMobile}>
                            <Link href="#sobre" className={classes.headerMenu}>
                                SOBRE
                            </Link>
                            <Link
                                href="#ondeestamos"
                                className={classes.headerMenu}
                            >
                                ONDE ESTAMOS
                            </Link>
                            <Link
                                href="#entregadores"
                                className={classes.headerMenu}
                            >
                                ENTREGADORES
                            </Link>
                            <Link
                                href="#empresas"
                                className={classes.headerMenu}
                            >
                                EMPRESAS
                            </Link>
                        </div>
                    </Hidden>
                </AppBar>
            </ElevationScroll>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.down("xs")]: {
        headerTop: {
            backgroundColor: "transparent",
            transition: "all 0.2s",
        },
        headerScroll: {
            backgroundColor: "#0080ff",
            transition: "all 0.2s",
        },
        headerMenuMobile: {
            display: "flex",
            justifyContent: "space-around",
            padding: "1rem 0",
        },
        headerContainer: {
            height: "95px",
            display: "flex",
        },
        headerToolbar: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        headerMenu: {
            color: "#fff",
            fontFamily: "Cocogoose",
            fontSize: ".7rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        headerEntrar: {
            backgroundColor: "#fff",
            color: "#0080ff",
            padding: ".6rem 2rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
    },
    [theme.breakpoints.up("sm")]: {
        headerTop: {
            backgroundColor: "transparent",
            transition: "all 0.2s",
        },
        headerScroll: {
            backgroundColor: "#0080ff",
            transition: "all 0.2s",
        },
        headerMenuMobile: {
            display: "flex",
            justifyContent: "space-around",
            padding: "1rem 0",
        },
        headerContainer: {
            height: "95px",
            display: "flex",
        },
        headerToolbar: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        headerMenu: {
            color: "#fff",
            fontFamily: "Cocogoose",
            fontSize: "1rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        headerEntrar: {
            backgroundColor: "#fff",
            color: "#0080ff",
            padding: ".6rem 2rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
    },
    [theme.breakpoints.up("md")]: {
        headerTop: {
            backgroundColor: "transparent",
            transition: "all 0.2s",
        },
        headerScroll: {
            backgroundColor: "#0080ff",
            transition: "all 0.2s",
        },
        headerContainer: {
            height: "95px",
            display: "flex",
        },
        headerToolbar: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        headerMenu: {
            color: "#fff",
            fontFamily: "Cocogoose",
            marginRight: "3rem",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
        headerEntrar: {
            backgroundColor: "#fff",
            color: "#0080ff",
            padding: ".6rem 2rem",
            borderRadius: "1.7rem",
            fontFamily: "Cocogoose",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
            },
        },
    },
}));
